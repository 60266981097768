export const COURSE_CONTENT_LIST_REQUEST = "COURSE_CONTENT_LIST_REQUEST";
export const COURSE_CONTENT_LIST_SUCCESS = "COURSE_CONTENT_LIST_SUCCESS";
export const COURSE_CONTENT_LIST_FAIL = "COURSE_CONTENT_LIST_FAIL";

export const COURSE_CONTENT_DETAILS_REQUEST = "COURSE_CONTENT_DETAILS_REQUEST";
export const COURSE_CONTENT_DETAILS_SUCCESS = "COURSE_CONTENT_DETAILS_SUCCESS";
export const COURSE_CONTENT_DETAILS_FAIL = "COURSE_CONTENT_DETAILS_FAIL";

export const COURSE_CONTENT_SAVE_REQUEST = "COURSE_CONTENT_SAVE_REQUEST";
export const COURSE_CONTENT_SAVE_SUCCESS = "COURSE_CONTENT_SAVE_SUCCESS";
export const COURSE_CONTENT_SAVE_FAIL = "COURSE_CONTENT_SAVE_FAIL";

export const COURSE_CONTENT_DELETE_REQUEST = "COURSE_CONTENT_DELETE_REQUEST";
export const COURSE_CONTENT_DELETE_SUCCESS = "COURSE_CONTENT_DELETE_SUCCESS";
export const COURSE_CONTENT_DELETE_FAIL = "COURSE_CONTENT_DELETE_FAIL";

  