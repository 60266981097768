export const FOOTER_SECTION_LIST_REQUEST = "FOOTER_SECTION_LIST_REQUEST";
export const FOOTER_SECTION_LIST_SUCCESS = "FOOTER_SECTION_LIST_SUCCESS";
export const FOOTER_SECTION_LIST_FAIL = "FOOTER_SECTION_LIST_FAIL";

export const FOOTER_SECTION_DETAILS_REQUEST = "FOOTER_SECTION_DETAILS_REQUEST";
export const FOOTER_SECTION_DETAILS_SUCCESS = "FOOTER_SECTION_DETAILS_SUCCESS";
export const FOOTER_SECTION_DETAILS_FAIL = "FOOTER_SECTION_DETAILS_FAIL";

export const FOOTER_SECTION_SAVE_REQUEST = "FOOTER_SECTION_SAVE_REQUEST";
export const FOOTER_SECTION_SAVE_SUCCESS = "FOOTER_SECTION_SAVE_SUCCESS";
export const FOOTER_SECTION_SAVE_FAIL = "FOOTER_SECTION_SAVE_FAIL";

export const FOOTER_SECTION_DELETE_REQUEST = "FOOTER_SECTION_DELETE_REQUEST";
export const FOOTER_SECTION_DELETE_SUCCESS = "FOOTER_SECTION_DELETE_SUCCESS";
export const FOOTER_SECTION_DELETE_FAIL = "FOOTER_SECTION_DELETE_FAIL";
