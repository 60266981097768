export const MENU_SECTION_LIST_REQUEST = "MENU_SECTION_LIST_REQUEST";
export const MENU_SECTION_LIST_SUCCESS = "MENU_SECTION_LIST_SUCCESS";
export const MENU_SECTION_LIST_FAIL = "MENU_SECTION_LIST_FAIL";

export const MENU_SECTION_DETAILS_REQUEST = "MENU_SECTION_DETAILS_REQUEST";
export const MENU_SECTION_DETAILS_SUCCESS = "MENU_SECTION_DETAILS_SUCCESS";
export const MENU_SECTION_DETAILS_FAIL = "MENU_SECTION_DETAILS_FAIL";

export const MENU_SECTION_SAVE_REQUEST = "MENU_SECTION_SAVE_REQUEST";
export const MENU_SECTION_SAVE_SUCCESS = "MENU_SECTION_SAVE_SUCCESS";
export const MENU_SECTION_SAVE_FAIL = "MENU_SECTION_SAVE_FAIL";

export const MENU_SECTION_DELETE_REQUEST = "MENU_SECTION_DELETE_REQUEST";
export const MENU_SECTION_DELETE_SUCCESS = "MENU_SECTION_DELETE_SUCCESS";
export const MENU_SECTION_DELETE_FAIL = "MENU_SECTION_DELETE_FAIL";

  