export const TECHNOLOGY_SERVICE_LIST_REQUEST = "TECHNOLOGY_SERVICE_LIST_REQUEST";
export const TECHNOLOGY_SERVICE_LIST_SUCCESS = "TECHNOLOGY_SERVICE_LIST_SUCCESS";
export const TECHNOLOGY_SERVICE_LIST_FAIL = "TECHNOLOGY_SERVICE_LIST_FAIL";

export const TECHNOLOGY_SERVICE_DETAILS_REQUEST = "TECHNOLOGY_SERVICE_DETAILS_REQUEST";
export const TECHNOLOGY_SERVICE_DETAILS_SUCCESS = "TECHNOLOGY_SERVICE_DETAILS_SUCCESS";
export const TECHNOLOGY_SERVICE_DETAILS_FAIL = "TECHNOLOGY_SERVICE_DETAILS_FAIL";

export const TECHNOLOGY_SERVICE_SAVE_REQUEST = "TECHNOLOGY_SERVICE_SAVE_REQUEST";
export const TECHNOLOGY_SERVICE_SAVE_SUCCESS = "TECHNOLOGY_SERVICE_SAVE_SUCCESS";
export const TECHNOLOGY_SERVICE_SAVE_FAIL = "TECHNOLOGY_SERVICE_SAVE_FAIL";

export const TECHNOLOGY_SERVICE_DELETE_REQUEST = "TECHNOLOGY_SERVICE_DELETE_REQUEST";
export const TECHNOLOGY_SERVICE_DELETE_SUCCESS = "TECHNOLOGY_SERVICE_DELETE_SUCCESS";
export const TECHNOLOGY_SERVICE_DELETE_FAIL = "TECHNOLOGY_SERVICE_DELETE_FAIL";

  