export const TRAINING_TYPE_LIST_REQUEST = "TRAINING_TYPE_LIST_REQUEST";
export const TRAINING_TYPE_LIST_SUCCESS = "TRAINING_TYPE_LIST_SUCCESS";
export const TRAINING_TYPE_LIST_FAIL = "TRAINING_TYPE_LIST_FAIL";

export const TRAINING_TYPE_DETAILS_REQUEST = "TRAINING_TYPE_DETAILS_REQUEST";
export const TRAINING_TYPE_DETAILS_SUCCESS = "TRAINING_TYPE_DETAILS_SUCCESS";
export const TRAINING_TYPE_DETAILS_FAIL = "TRAINING_TYPE_DETAILS_FAIL";

export const TRAINING_TYPE_SAVE_REQUEST = "TRAINING_TYPE_SAVE_REQUEST";
export const TRAINING_TYPE_SAVE_SUCCESS = "TRAINING_TYPE_SAVE_SUCCESS";
export const TRAINING_TYPE_SAVE_FAIL = "TRAINING_TYPE_SAVE_FAIL";

export const TRAINING_TYPE_DELETE_REQUEST = "TRAINING_TYPE_DELETE_REQUEST";
export const TRAINING_TYPE_DELETE_SUCCESS = "TRAINING_TYPE_DELETE_SUCCESS";
export const TRAINING_TYPE_DELETE_FAIL = "TRAINING_TYPE_DELETE_FAIL";

  