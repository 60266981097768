export const COURSE_REQUIREMENT_LIST_REQUEST = "COURSE_REQUIREMENT_LIST_REQUEST";
export const COURSE_REQUIREMENT_LIST_SUCCESS = "COURSE_REQUIREMENT_LIST_SUCCESS";
export const COURSE_REQUIREMENT_LIST_FAIL = "COURSE_REQUIREMENT_LIST_FAIL";

export const COURSE_REQUIREMENT_DETAILS_REQUEST = "COURSE_REQUIREMENT_DETAILS_REQUEST";
export const COURSE_REQUIREMENT_DETAILS_SUCCESS = "COURSE_REQUIREMENT_DETAILS_SUCCESS";
export const COURSE_REQUIREMENT_DETAILS_FAIL = "COURSE_REQUIREMENT_DETAILS_FAIL";

export const COURSE_REQUIREMENT_SAVE_REQUEST = "COURSE_REQUIREMENT_SAVE_REQUEST";
export const COURSE_REQUIREMENT_SAVE_SUCCESS = "COURSE_REQUIREMENT_SAVE_SUCCESS";
export const COURSE_REQUIREMENT_SAVE_FAIL = "COURSE_REQUIREMENT_SAVE_FAIL";

export const COURSE_REQUIREMENT_DELETE_REQUEST = "COURSE_REQUIREMENT_DELETE_REQUEST";
export const COURSE_REQUIREMENT_DELETE_SUCCESS = "COURSE_REQUIREMENT_DELETE_SUCCESS";
export const COURSE_REQUIREMENT_DELETE_FAIL = "COURSE_REQUIREMENT_DELETE_FAIL";
