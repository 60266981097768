export const BUSINESS_PRINCIPAL_DESCRIPTOR_LIST_REQUEST = "BUSINESS_PRINCIPAL_DESCRIPTOR_LIST_REQUEST";
export const BUSINESS_PRINCIPAL_DESCRIPTOR_LIST_SUCCESS = "BUSINESS_PRINCIPAL_DESCRIPTOR_LIST_SUCCESS";
export const BUSINESS_PRINCIPAL_DESCRIPTOR_LIST_FAIL = "BUSINESS_PRINCIPAL_DESCRIPTOR_LIST_FAIL";

export const BUSINESS_PRINCIPAL_DESCRIPTOR_DETAILS_REQUEST = "BUSINESS_PRINCIPAL_DESCRIPTOR_DETAILS_REQUEST";
export const BUSINESS_PRINCIPAL_DESCRIPTOR_DETAILS_SUCCESS = "BUSINESS_PRINCIPAL_DESCRIPTOR_DETAILS_SUCCESS";
export const BUSINESS_PRINCIPAL_DESCRIPTOR_DETAILS_FAIL = "BUSINESS_PRINCIPAL_DESCRIPTOR_DETAILS_FAIL";

export const BUSINESS_PRINCIPAL_DESCRIPTOR_SAVE_REQUEST = "BUSINESS_PRINCIPAL_DESCRIPTOR_SAVE_REQUEST";
export const BUSINESS_PRINCIPAL_DESCRIPTOR_SAVE_SUCCESS = "BUSINESS_PRINCIPAL_DESCRIPTOR_SAVE_SUCCESS";
export const BUSINESS_PRINCIPAL_DESCRIPTOR_SAVE_FAIL = "BUSINESS_PRINCIPAL_DESCRIPTOR_SAVE_FAIL";

export const BUSINESS_PRINCIPAL_DESCRIPTOR_DELETE_REQUEST = "BUSINESS_PRINCIPAL_DESCRIPTOR_DELETE_REQUEST";
export const BUSINESS_PRINCIPAL_DESCRIPTOR_DELETE_SUCCESS = "BUSINESS_PRINCIPAL_DESCRIPTOR_DELETE_SUCCESS";
export const BUSINESS_PRINCIPAL_DESCRIPTOR_DELETE_FAIL = "BUSINESS_PRINCIPAL_DESCRIPTOR_DELETE_FAIL";

