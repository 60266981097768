export const CTA_PACKAGE_HOURLY_LIST_REQUEST = "CTA_PACKAGE_HOURLY_LIST_REQUEST";
export const CTA_PACKAGE_HOURLY_LIST_SUCCESS = "CTA_PACKAGE_HOURLY_LIST_SUCCESS";
export const CTA_PACKAGE_HOURLY_LIST_FAIL = "CTA_PACKAGE_HOURLY_LIST_FAIL";

export const CTA_PACKAGE_HOURLY_DETAILS_REQUEST = "CTA_PACKAGE_HOURLY_DETAILS_REQUEST";
export const CTA_PACKAGE_HOURLY_DETAILS_SUCCESS = "CTA_PACKAGE_HOURLY_DETAILS_SUCCESS";
export const CTA_PACKAGE_HOURLY_DETAILS_FAIL = "CTA_PACKAGE_HOURLY_DETAILS_FAIL";

export const CTA_PACKAGE_HOURLY_SAVE_REQUEST = "CTA_PACKAGE_HOURLY_SAVE_REQUEST";
export const CTA_PACKAGE_HOURLY_SAVE_SUCCESS = "CTA_PACKAGE_HOURLY_SAVE_SUCCESS";
export const CTA_PACKAGE_HOURLY_SAVE_FAIL = "CTA_PACKAGE_HOURLY_SAVE_FAIL";

export const CTA_PACKAGE_HOURLY_DELETE_REQUEST = "CTA_PACKAGE_HOURLY_DELETE_REQUEST";
export const CTA_PACKAGE_HOURLY_DELETE_SUCCESS = "CTA_PACKAGE_HOURLY_DELETE_SUCCESS";
export const CTA_PACKAGE_HOURLY_DELETE_FAIL = "CTA_PACKAGE_HOURLY_DELETE_FAIL";

  