import {
    JOB_SEEKER_INFO_LIST_REQUEST,
    JOB_SEEKER_INFO_LIST_SUCCESS,
    JOB_SEEKER_INFO_LIST_FAIL,
    JOB_SEEKER_INFO_DETAILS_REQUEST,
    JOB_SEEKER_INFO_DETAILS_SUCCESS,
    JOB_SEEKER_INFO_DETAILS_FAIL,
    JOB_SEEKER_INFO_SAVE_REQUEST,
    JOB_SEEKER_INFO_SAVE_SUCCESS,
    JOB_SEEKER_INFO_SAVE_FAIL,
    JOB_SEEKER_INFO_DELETE_REQUEST,
    JOB_SEEKER_INFO_DELETE_SUCCESS,
    JOB_SEEKER_INFO_DELETE_FAIL
} from '../constants/jobSeekerConstant';

import { axiosWithoutToken, axiosWithToken, axiosWithTokenAndMultipartData } from '../../helpers/axios';
import { config } from "../../config";

const BASE_API_URL = config.BASE_API_URL;

const listJobSeekerInfo = () => async (dispatch) => {
    try {
        dispatch({ type: JOB_SEEKER_INFO_LIST_REQUEST });
        const { data } = await axiosWithoutToken.get(`${BASE_API_URL}/BpJobSeekerInformation/searchAll`);

        if (data.status === true) {
            dispatch({ type: JOB_SEEKER_INFO_LIST_SUCCESS, payload: data.data ? data.data.item1 : [] });

        } else {
            dispatch({ type: JOB_SEEKER_INFO_LIST_FAIL, payload: data.message });
        }
        console.log('Data from API:', data.data.item1);
    }
    catch (error) {
        dispatch({ type: JOB_SEEKER_INFO_LIST_FAIL, payload: error.message });
    }
};

const detailsJobSeekerInfo = (id) => async (dispatch) => {
    try {
        dispatch({ type: JOB_SEEKER_INFO_DETAILS_REQUEST });
        const { data } = await axiosWithoutToken.get("/BpJobSeekerInformation/detail/" + id);
        dispatch({ type: JOB_SEEKER_INFO_DETAILS_SUCCESS, payload: data });
    }
    catch (error) {
        dispatch({ type: JOB_SEEKER_INFO_DETAILS_FAIL, payload: error.message });
    }
};

const saveJobSeekerInfo = (item, id) => async (dispatch) => {
    try {
        dispatch({ type: JOB_SEEKER_INFO_SAVE_REQUEST, payload: item });
        if (!id) {
            console.log('create');
            // eslint-disable-next-line
            const formatData = delete item.id;
            const { data } = await axiosWithTokenAndMultipartData.post("/BpJobSeekerInformation/Create", item);
            if (data.status === true) {
                dispatch({ type: JOB_SEEKER_INFO_SAVE_SUCCESS, payload: data });
            } else {
                dispatch({ type: JOB_SEEKER_INFO_SAVE_FAIL, payload: data.message });
            }
        } else {
            console.log('update');
            const { data } = await axiosWithTokenAndMultipartData.post("/BpJobSeekerInformation/Update", item);
            if (data.status === true) {
                dispatch({ type: JOB_SEEKER_INFO_SAVE_SUCCESS, payload: data });
            } else {
                dispatch({ type: JOB_SEEKER_INFO_SAVE_FAIL, payload: data.message });
            }
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: JOB_SEEKER_INFO_SAVE_FAIL, payload: error.message });
    }
};

const deleteJobSeekerInfo = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOB_SEEKER_INFO_DELETE_REQUEST });
        const { data } = await axiosWithToken.delete("/BpJobSeekerInformation/" + id);
        if (data.status === true) {
            dispatch({ type: JOB_SEEKER_INFO_DELETE_SUCCESS, payload: data, success: true });
        } else {
            dispatch({ type: JOB_SEEKER_INFO_DELETE_FAIL, payload: data.message });
        }
    }
    catch (error) {
        dispatch({ type: JOB_SEEKER_INFO_DELETE_FAIL, payload: error.message });
    }
};

export { saveJobSeekerInfo, deleteJobSeekerInfo, listJobSeekerInfo, detailsJobSeekerInfo };
