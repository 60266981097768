export const SUBMENU_LIST_REQUEST = "SUBMENU_LIST_REQUEST";
export const SUBMENU_LIST_SUCCESS = "SUBMENU_LIST_SUCCESS";
export const SUBMENU_LIST_FAIL = "SUBMENU_LIST_FAIL";

export const SUBMENU_DETAILS_REQUEST = "SUBMENU_DETAILS_REQUEST";
export const SUBMENU_DETAILS_SUCCESS = "SUBMENU_DETAILS_SUCCESS";
export const SUBMENU_DETAILS_FAIL = "SUBMENU_DETAILS_FAIL";

export const SUBMENU_SAVE_REQUEST = "SUBMENU_SAVE_REQUEST";
export const SUBMENU_SAVE_SUCCESS = "SUBMENU_SAVE_SUCCESS";
export const SUBMENU_SAVE_FAIL = "SUBMENU_SAVE_FAIL";

export const SUBMENU_DELETE_REQUEST = "SUBMENU_DELETE_REQUEST";
export const SUBMENU_DELETE_SUCCESS = "SUBMENU_DELETE_SUCCESS";
export const SUBMENU_DELETE_FAIL = "SUBMENU_DELETE_FAIL";

  