export const TECHNOLOGY_PARTNER_LIST_REQUEST = "TECHNOLOGY_PARTNER_LIST_REQUEST";
export const TECHNOLOGY_PARTNER_LIST_SUCCESS = "TECHNOLOGY_PARTNER_LIST_SUCCESS";
export const TECHNOLOGY_PARTNER_LIST_FAIL = "TECHNOLOGY_PARTNER_LIST_FAIL";

export const TECHNOLOGY_PARTNER_DETAILS_REQUEST = "TECHNOLOGY_PARTNER_DETAILS_REQUEST";
export const TECHNOLOGY_PARTNER_DETAILS_SUCCESS = "TECHNOLOGY_PARTNER_DETAILS_SUCCESS";
export const TECHNOLOGY_PARTNER_DETAILS_FAIL = "TECHNOLOGY_PARTNER_DETAILS_FAIL";

export const TECHNOLOGY_PARTNER_SAVE_REQUEST = "TECHNOLOGY_PARTNER_SAVE_REQUEST";
export const TECHNOLOGY_PARTNER_SAVE_SUCCESS = "TECHNOLOGY_PARTNER_SAVE_SUCCESS";
export const TECHNOLOGY_PARTNER_SAVE_FAIL = "TECHNOLOGY_PARTNER_SAVE_FAIL";

export const TECHNOLOGY_PARTNER_DELETE_REQUEST = "TECHNOLOGY_PARTNER_DELETE_REQUEST";
export const TECHNOLOGY_PARTNER_DELETE_SUCCESS = "TECHNOLOGY_PARTNER_DELETE_SUCCESS";
export const TECHNOLOGY_PARTNER_DELETE_FAIL = "TECHNOLOGY_PARTNER_DELETE_FAIL";

  