import React, { useEffect } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import Controls from "../../../components/controls/Controls";
import { useForm, Form } from '../../../components/UseForm/useForm';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

const initialFValues = {
    id: '',
    jobTitle: '',
    categoryName: '',
    jobStatus: true,
    jobType: '',
    location: '',
    jobDescription: EditorState.createEmpty(),
    jobOtherDescription: EditorState.createEmpty(),
    aboutComapny: EditorState.createEmpty(),
    salaryRange: '',
    applyDeadline: '',
    careerLevel: '',
    otherBenefit: '',
    websiteLink: '',
    numberOfVacancy:'',
}

export default function JobDetailForm(props) {
    const { addOrEdit, recordForEdit, loadingSave, setOpenPopup } = props

    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        if ('jobTitle' in fieldValues)
            temp.jobTitle = fieldValues.jobTitle ? "" : "This field is required."
        if ('categoryName' in fieldValues)
            temp.categoryName = fieldValues.categoryName ? "" : "This field is required."
        if ('applyDeadline' in fieldValues)
            temp.applyDeadline = fieldValues.applyDeadline ? "" : "This field is required."
        if ('jobStatus' in fieldValues)
            temp.jobStatus = "";
        if ('jobType' in fieldValues)
            temp.jobType = fieldValues.jobType ? "" : "This field is required."
        if ('location' in fieldValues)
            temp.location = fieldValues.location ? "" : "This field is required."
        if ('jobDescription' in fieldValues)
            temp.jobDescription = fieldValues.jobDescription ? "" : "This field is required."
        if ('jobOtherDescription' in fieldValues)
            temp.jobOtherDescription = "";
        if ('aboutComapny' in fieldValues)
            temp.aboutCompany = "";
        if ('salaryRange' in fieldValues)
            temp.salaryRange = "";
        if ('careerLevel' in fieldValues)
            temp.careerLevel = "";
        if ('otherBenefit' in fieldValues)
            temp.otherBenefit = "";
        if ('websiteLink' in fieldValues)
            temp.websiteLink = "";
        if ('numberOfVacancy' in fieldValues)
            temp.numberOfVacancy = "";
        setErrors({
            ...temp
        })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        handleEditorInput,
    } = useForm(initialFValues, true, validate);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                values['jobDescription'] = draftToHtml(convertToRaw(values.jobDescription.getCurrentContent()));
                values['jobOtherDescription'] = draftToHtml(convertToRaw(values?.jobOtherDescription.getCurrentContent()));
                values['aboutComapny'] = draftToHtml(convertToRaw(values?.aboutComapny.getCurrentContent()));
            } catch (e) {
                console.log(e);
            } finally {
                addOrEdit(values, resetForm);
            }
        }
    };




    // useEffect(() => {
    //     if (recordForEdit != null) {
    //         setValues({
    //             ...recordForEdit

    //         });

    //         const html = recordForEdit.jobDescription;
    //         const contentBlock = htmlToDraft(html);
    //         if (contentBlock) {
    //             const contentState = ContentState.createFromBlockArray(
    //                 contentBlock.contentBlocks
    //             );
    //             const content = EditorState.createWithContent(contentState);
    //             setValues({
    //                 ...recordForEdit,
    //                 jobDescription: content,
    //                 jobOtherDescription: content,
    //                 aboutComapny:content,

    //             });
    //         }
    //     }
    // }, [recordForEdit, setValues]);



    useEffect(() => {
        if (recordForEdit != null) {
            setValues((prevValues) => ({
                ...prevValues,
                ...recordForEdit,
                jobStatus: recordForEdit.jobStatus ? true : false,
                applyDeadline: recordForEdit.applyDeadline ? recordForEdit.applyDeadline : '',

            }));

            const htmlDescription = recordForEdit.jobDescription;
            const contentBlockDescription = htmlToDraft(htmlDescription);
            if (contentBlockDescription) {
                const contentStateDescription = ContentState.createFromBlockArray(
                    contentBlockDescription.contentBlocks
                );
                const contentDescription = EditorState.createWithContent(contentStateDescription);
                setValues((prevValues) => ({
                    ...prevValues,
                    jobDescription: contentDescription,
                }));
            }

            const htmlOtherDescription = recordForEdit.jobOtherDescription;
            const contentBlockOtherDescription = htmlToDraft(htmlOtherDescription);
            if (contentBlockOtherDescription) {
                const contentStateOtherDescription = ContentState.createFromBlockArray(
                    contentBlockOtherDescription.contentBlocks
                );
                const contentOtherDescription = EditorState.createWithContent(contentStateOtherDescription);
                setValues((prevValues) => ({
                    ...prevValues,
                    jobOtherDescription: contentOtherDescription,
                }));
            }

            const htmlAboutCompany = recordForEdit.aboutComapny;
            const contentBlockAboutCompany = htmlToDraft(htmlAboutCompany);
            if (contentBlockAboutCompany) {
                const contentStateAboutCompany = ContentState.createFromBlockArray(
                    contentBlockAboutCompany.contentBlocks
                );
                const contentAboutCompany = EditorState.createWithContent(contentStateAboutCompany);
                setValues((prevValues) => ({
                    ...prevValues,
                    aboutComapny: contentAboutCompany,
                }));
            }
        }
    }, [recordForEdit, setValues]);




    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>

                    <Controls.Input
                        name="jobTitle"
                        label="Job Title*"
                        value={values.jobTitle}
                        onChange={handleInputChange}
                        error={errors.jobTitle}
                    />
                    <Controls.Input
                        name="categoryName"
                        label="Job Category Name*"
                        value={values.categoryName}
                        onChange={handleInputChange}
                        error={errors.categoryName}
                    />
                    <Controls.Input
                        name="jobStatus"
                        label="Job Status*"
                        value={values.jobStatus}
                        onChange={handleInputChange}
                        error={errors.jobStatus}
                    />
                    <Controls.Input
                        name="jobType"
                        label="job Type*"
                        value={values.jobType}
                        onChange={handleInputChange}
                        error={errors.jobType}
                    />
                    <Controls.Input
                        name="location"
                        label="location"
                        value={values.location}
                        onChange={handleInputChange}
                        error={errors.location}
                    />
                    <Controls.Input
                        name="salaryRange"
                        label="Salary Range"
                        value={values.salaryRange}
                        onChange={handleInputChange}
                        error={errors.salaryRange}
                    />

                    <Controls.Input
                        name="applyDeadline"
                        label="Apply Deadline*"
                        type="date"
                        value={values.applyDeadline}
                        onChange={handleInputChange}
                        error={errors.applyDeadline}
                    />
                    <Controls.Input
                        name="careerLevel"
                        label="Career Level"
                        value={values.careerLevel}
                        onChange={handleInputChange}
                        error={errors.careerLevel}
                    />
                    <Controls.Input
                        name="otherBenefit"
                        label="Other Benefit"
                        value={values.otherBenefit}
                        onChange={handleInputChange}
                        error={errors.otherBenefit}
                    />
                    <Controls.Input
                        name="websiteLink"
                        label="Website Link"
                        value={values.websiteLink}
                        onChange={handleInputChange}
                        error={errors.websiteLink}
                    />
                    <Controls.Input
                        name="numberOfVacancy"
                        label="Number Of Vacancy"
                        type="number"  // Set the type attribute to "number"
                        value={values.numberOfVacancy}
                        onChange={handleInputChange}
                        error={errors.numberOfVacancy}
                    />
                    <Controls.RichTextEditor
                        onEditorStateChange={value => handleEditorInput('jobDescription', value)} //handleEditorInput(name, value)
                        placeholder="job Description Content here..."
                        editorState={values.jobDescription}
                    />

                    <Controls.RichTextEditor
                        onEditorStateChange={value => handleEditorInput('jobOtherDescription', value)} //handleEditorInput(name, value)
                        placeholder="Job Other Description Content here..."
                        editorState={values.jobOtherDescription}
                    />
                    <Controls.RichTextEditor
                        onEditorStateChange={value => handleEditorInput('aboutComapny', value)} //handleEditorInput(name, value)
                        placeholder="About Company Content here..."
                        editorState={values.aboutComapny}
                    />



                    <div>
                        {loadingSave ? (
                            <CircularProgress size={26} />
                        ) : (<>
                            <Controls.Button
                                type="submit"
                                text="Submit" />
                            <Controls.Button
                                text="Reset"
                                color="default"
                                onClick={resetForm} />
                            <Controls.Button
                                text="Close"
                                color="default"
                                onClick={() => { setOpenPopup(false) }}
                            />
                        </>
                        )
                        }
                    </div>
                </Grid>
            </Grid>
        </Form>
    )
}