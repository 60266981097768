export const TRAINING_DETAIL_LIST_REQUEST = "TRAINING_DETAIL_LIST_REQUEST";
export const TRAINING_DETAIL_LIST_SUCCESS = "TRAINING_DETAIL_LIST_SUCCESS";
export const TRAINING_DETAIL_LIST_FAIL = "TRAINING_DETAIL_LIST_FAIL";

export const TRAINING_DETAIL_DETAILS_REQUEST = "TRAINING_DETAIL_DETAILS_REQUEST";
export const TRAINING_DETAIL_DETAILS_SUCCESS = "TRAINING_DETAIL_DETAILS_SUCCESS";
export const TRAINING_DETAIL_DETAILS_FAIL = "TRAINING_DETAIL_DETAILS_FAIL";

export const TRAINING_DETAIL_SAVE_REQUEST = "TRAINING_DETAIL_SAVE_REQUEST";
export const TRAINING_DETAIL_SAVE_SUCCESS = "TRAINING_DETAIL_SAVE_SUCCESS";
export const TRAINING_DETAIL_SAVE_FAIL = "TRAINING_DETAIL_SAVE_FAIL";

export const TRAINING_DETAIL_DELETE_REQUEST = "TRAINING_DETAIL_DELETE_REQUEST";
export const TRAINING_DETAIL_DELETE_SUCCESS = "TRAINING_DETAIL_DELETE_SUCCESS";
export const TRAINING_DETAIL_DELETE_FAIL = "TRAINING_DETAIL_DELETE_FAIL";

  