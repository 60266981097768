import { 
    CUSTOMER_REQUEST_LIST_REQUEST,
    CUSTOMER_REQUEST_LIST_FAIL,
    CUSTOMER_REQUEST_LIST_SUCCESS

 } from '../constants/customerRequestConstants';


function customerRequestListReducer(state={customerRequests:[]},action){
    switch(action.type){
        case CUSTOMER_REQUEST_LIST_REQUEST:
            return { loading:true, customerRequests:[] };
        case CUSTOMER_REQUEST_LIST_SUCCESS:
            return { loading:false, customerRequests:action.payload };
        case CUSTOMER_REQUEST_LIST_FAIL:
            return { loading:false, error: action.payload };
        default:
            return state;
    }
};


export { 
    customerRequestListReducer , 
 }; 
