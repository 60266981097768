export const COURSE_PURCHASE_LIST_REQUEST = "COURSE_PURCHASE_LIST_REQUEST";
export const COURSE_PURCHASE_LIST_SUCCESS = "COURSE_PURCHASE_LIST_SUCCESS";
export const COURSE_PURCHASE_LIST_FAIL = "COURSE_PURCHASE_LIST_FAIL";

export const COURSE_PURCHASE_DETAILS_REQUEST = "COURSE_PURCHASE_DETAILS_REQUEST";
export const COURSE_PURCHASE_DETAILS_SUCCESS = "COURSE_PURCHASE_DETAILS_SUCCESS";
export const COURSE_PURCHASE_DETAILS_FAIL = "COURSE_PURCHASE_DETAILS_FAIL";

export const COURSE_PURCHASE_SAVE_REQUEST = "COURSE_PURCHASE_SAVE_REQUEST";
export const COURSE_PURCHASE_SAVE_SUCCESS = "COURSE_PURCHASE_SAVE_SUCCESS";
export const COURSE_PURCHASE_SAVE_FAIL = "COURSE_PURCHASE_SAVE_FAIL";

export const COURSE_PURCHASE_DELETE_REQUEST = "COURSE_PURCHASE_DELETE_REQUEST";
export const COURSE_PURCHASE_DELETE_SUCCESS = "COURSE_PURCHASE_DELETE_SUCCESS";
export const COURSE_PURCHASE_DELETE_FAIL = "COURSE_PURCHASE_DELETE_FAIL";


