export const BLOG_POST_LIST_REQUEST = "BLOG_POST_LIST_REQUEST";
export const BLOG_POST_LIST_SUCCESS = "BLOG_POST_LIST_SUCCESS";
export const BLOG_POST_LIST_FAIL = "BLOG_POST_LIST_FAIL";

export const BLOG_POST_DETAILS_REQUEST = "BLOG_POST_DETAILS_REQUEST";
export const BLOG_POST_DETAILS_SUCCESS = "BLOG_POST_DETAILS_SUCCESS";
export const BLOG_POST_DETAILS_FAIL = "BLOG_POST_DETAILS_FAIL";

export const BLOG_POST_SAVE_REQUEST = "BLOG_POST_SAVE_REQUEST";
export const BLOG_POST_SAVE_SUCCESS = "BLOG_POST_SAVE_SUCCESS";
export const BLOG_POST_SAVE_FAIL = "BLOG_POST_SAVE_FAIL";

export const BLOG_POST_DELETE_REQUEST = "BLOG_POST_DELETE_REQUEST";
export const BLOG_POST_DELETE_SUCCESS = "BLOG_POST_DELETE_SUCCESS";
export const BLOG_POST_DELETE_FAIL = "BLOG_POST_DELETE_FAIL";

  