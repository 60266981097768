export const CONSULTANCY_ASSIGNMENT_LIST_REQUEST = "CONSULTANCY_ASSIGNMENT_LIST_REQUEST";
export const CONSULTANCY_ASSIGNMENT_LIST_SUCCESS = "CONSULTANCY_ASSIGNMENT_LIST_SUCCESS";
export const CONSULTANCY_ASSIGNMENT_LIST_FAIL = "CONSULTANCY_ASSIGNMENT_LIST_FAIL";

export const CONSULTANCY_ASSIGNMENT_DETAILS_REQUEST = "CONSULTANCY_ASSIGNMENT_DETAILS_REQUEST";
export const CONSULTANCY_ASSIGNMENT_DETAILS_SUCCESS = "CONSULTANCY_ASSIGNMENT_DETAILS_SUCCESS";
export const CONSULTANCY_ASSIGNMENT_DETAILS_FAIL = "CONSULTANCY_ASSIGNMENT_DETAILS_FAIL";

export const CONSULTANCY_ASSIGNMENT_SAVE_REQUEST = "CONSULTANCY_ASSIGNMENT_SAVE_REQUEST";
export const CONSULTANCY_ASSIGNMENT_SAVE_SUCCESS = "CONSULTANCY_ASSIGNMENT_SAVE_SUCCESS";
export const CONSULTANCY_ASSIGNMENT_SAVE_FAIL = "CONSULTANCY_ASSIGNMENT_SAVE_FAIL";

export const CONSULTANCY_ASSIGNMENT_DELETE_REQUEST = "CONSULTANCY_ASSIGNMENT_DELETE_REQUEST";
export const CONSULTANCY_ASSIGNMENT_DELETE_SUCCESS = "CONSULTANCY_ASSIGNMENT_DELETE_SUCCESS";
export const CONSULTANCY_ASSIGNMENT_DELETE_FAIL = "CONSULTANCY_ASSIGNMENT_DELETE_FAIL";

