export const CTA_FUNCTION_LIST_REQUEST = "CTA_FUNCTION_LIST_REQUEST";
export const CTA_FUNCTION_LIST_SUCCESS = "CTA_FUNCTION_LIST_SUCCESS";
export const CTA_FUNCTION_LIST_FAIL = "CTA_FUNCTION_LIST_FAIL";

export const CTA_FUNCTION_DETAILS_REQUEST = "CTA_FUNCTION_DETAILS_REQUEST";
export const CTA_FUNCTION_DETAILS_SUCCESS = "CTA_FUNCTION_DETAILS_SUCCESS";
export const CTA_FUNCTION_DETAILS_FAIL = "CTA_FUNCTION_DETAILS_FAIL";

export const CTA_FUNCTION_SAVE_REQUEST = "CTA_FUNCTION_SAVE_REQUEST";
export const CTA_FUNCTION_SAVE_SUCCESS = "CTA_FUNCTION_SAVE_SUCCESS";
export const CTA_FUNCTION_SAVE_FAIL = "CTA_FUNCTION_SAVE_FAIL";

// export const CTA_FUNCTION_DELETE_REQUEST = "CTA_FUNCTION_DELETE_REQUEST";
// export const CTA_FUNCTION_DELETE_SUCCESS = "CTA_FUNCTION_DELETE_SUCCESS";
// export const CTA_FUNCTION_DELETE_FAIL = "CTA_FUNCTION_DELETE_FAIL";

export const CTA_FUNCTION_MODEL_LIST_REQUEST = "CTA_FUNCTION_MODEL_LIST_REQUEST";
export const CTA_FUNCTION_MODEL_LIST_SUCCESS = "CTA_FUNCTION_MODEL_LIST_SUCCESS";
export const CTA_FUNCTION_MODEL_LIST_FAIL = "CTA_FUNCTION_MODEL_LIST_FAIL";

export const CTA_FUNCTION_DOCUMENT_LIST_REQUEST = "CTA_FUNCTION_DOCUMENT_LIST_REQUEST";
export const CTA_FUNCTION_DOCUMENT_LIST_SUCCESS = "CTA_FUNCTION_DOCUMENT_LIST_SUCCESS";
export const CTA_FUNCTION_DOCUMENT_LIST_FAIL = "CTA_FUNCTION_DOCUMENT_LIST_FAIL";


export const CTA_FUNCTION_DOCUMENT_SAVE_REQUEST = "CTA_FUNCTION_DOCUMENT_SAVE_REQUEST";
export const CTA_FUNCTION_DOCUMENT_SAVE_SUCCESS = "CTA_FUNCTION_DOCUMENT_SAVE_SUCCESS";
export const CTA_FUNCTION_DOCUMENT_SAVE_FAIL = "CTA_FUNCTION_DOCUMENT_SAVE_FAIL";

export const CTA_FUNCTION_DOCUMENT_DELETE_REQUEST = "CTA_FUNCTION_DOCUMENT_DELETE_REQUEST";
export const CTA_FUNCTION_DOCUMENT_DELETE_SUCCESS = "CTA_FUNCTION_DOCUMENT_DELETE_SUCCESS";
export const CTA_FUNCTION_DOCUMENT_DELETE_FAIL = "CTA_FUNCTION_DOCUMENT_DELETE_FAIL";

export const CONSULTANCY_SUMMERY_DETAILS_REQUEST = "CONSULTANCY_SUMMERY_DETAILS_REQUEST";
export const CONSULTANCY_SUMMERY_DETAILS_SUCCESS = "CONSULTANCY_SUMMERY_DETAILS_SUCCESS";
export const CONSULTANCY_SUMMERY_DETAILS_FAIL = "CONSULTANCY_SUMMERY_DETAILS_FAIL";