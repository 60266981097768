export const CUSTOMER_REQUEST_LIST_REQUEST = "CUSTOMER_REQUEST_LIST_REQUEST";
export const CUSTOMER_REQUEST_LIST_SUCCESS = "CUSTOMER_REQUEST_LIST_SUCCESS";
export const CUSTOMER_REQUEST_LIST_FAIL = "CUSTOMER_REQUEST_LIST_FAIL";

// export const PAYMENT_PACKAGE_DETAILS_REQUEST = "PAYMENT_PACKAGE_DETAILS_REQUEST";
// export const PAYMENT_PACKAGE_DETAILS_SUCCESS = "PAYMENT_PACKAGE_DETAILS_SUCCESS";
// export const PAYMENT_PACKAGE_DETAILS_FAIL = "PAYMENT_PACKAGE_DETAILS_FAIL";

// export const PAYMENT_PACKAGE_SAVE_REQUEST = "PAYMENT_PACKAGE_SAVE_REQUEST";
// export const PAYMENT_PACKAGE_SAVE_SUCCESS = "PAYMENT_PACKAGE_SAVE_SUCCESS";
// export const PAYMENT_PACKAGE_SAVE_FAIL = "PAYMENT_PACKAGE_SAVE_FAIL";

// export const PAYMENT_PACKAGE_DELETE_REQUEST = "PAYMENT_PACKAGE_DELETE_REQUEST";
// export const PAYMENT_PACKAGE_DELETE_SUCCESS = "PAYMENT_PACKAGE_DELETE_SUCCESS";
// export const PAYMENT_PACKAGE_DELETE_FAIL = "PAYMENT_PACKAGE_DELETE_FAIL";

  