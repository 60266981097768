export const COMPANY_SIZE_LIST_REQUEST = "COMPANY_SIZE_LIST_REQUEST";
export const COMPANY_SIZE_LIST_SUCCESS = "COMPANY_SIZE_LIST_SUCCESS";
export const COMPANY_SIZE_LIST_FAIL = "COMPANY_SIZE_LIST_FAIL";

export const COMPANY_SIZE_DETAILS_REQUEST = "COMPANY_SIZE_DETAILS_REQUEST";
export const COMPANY_SIZE_DETAILS_SUCCESS = "COMPANY_SIZE_DETAILS_SUCCESS";
export const COMPANY_SIZE_DETAILS_FAIL = "COMPANY_SIZE_DETAILS_FAIL";

export const COMPANY_SIZE_SAVE_REQUEST = "COMPANY_SIZE_SAVE_REQUEST";
export const COMPANY_SIZE_SAVE_SUCCESS = "COMPANY_SIZE_SAVE_SUCCESS";
export const COMPANY_SIZE_SAVE_FAIL = "COMPANY_SIZE_SAVE_FAIL";

export const COMPANY_SIZE_DELETE_REQUEST = "COMPANY_SIZE_DELETE_REQUEST";
export const COMPANY_SIZE_DELETE_SUCCESS = "COMPANY_SIZE_DELETE_SUCCESS";
export const COMPANY_SIZE_DELETE_FAIL = "COMPANY_SIZE_DELETE_FAIL";

  