import React, {useState} from "react";
import {PayPalButton} from "react-paypal-button-v2";
import Notification from "../Notification/Notification";

const PaypalSubscriptionBtn = (props) => {
	const {clientId, subscription_paln_id, paypalSubscriptionApprove} = props;
	const [notify, setNotify] = useState({isOpen: false, message: "", type: ""});

	const paypalBtnStyle = {
		shape: "rect",
		color: "blue",
		size: "large",
		layout: "horizontal",
		label: "subscribe",
		tagline: false,
	};

	return (
		<>
			<PayPalButton
				style={paypalBtnStyle}
				options={{
					clientId: clientId,
					disableFunding: "paylater",
					vault: true,
					intent: "subscription",
				}}
				createSubscription={(data, actions) => {
					return actions.subscription.create({
						plan_id: subscription_paln_id,
					});
				}}
				onApprove={(data, actions) => {
					return actions.subscription.get().then(function (details) {
						// console.log("details-----> ", details);
						paypalSubscriptionApprove(data);
					});
				}}
				onCancel={(data) => {
					setNotify({
						isOpen: true,
						message: "Payment Canceled",
						type: "warning",
					});
					console.log("User Canceled: ", data);
				}}
				onError={(error) => {
					setNotify({
						isOpen: true,
						message: "Some Error occured. Please try again later or contact us",
						type: "error",
					});
					console.log("Error Occured: ", error);
				}}
			/>

			<Notification notify={notify} setNotify={setNotify} />
		</>
	);
};

export default PaypalSubscriptionBtn;
