export const HOMEPAGE_SLIDER_LIST_REQUEST = "HOMEPAGE_SLIDER_LIST_REQUEST";
export const HOMEPAGE_SLIDER_LIST_SUCCESS = "HOMEPAGE_SLIDER_LIST_SUCCESS";
export const HOMEPAGE_SLIDER_LIST_FAIL = "HOMEPAGE_SLIDER_LIST_FAIL";

export const HOMEPAGE_SLIDER_DETAILS_REQUEST = "HOMEPAGE_SLIDER_DETAILS_REQUEST";
export const HOMEPAGE_SLIDER_DETAILS_SUCCESS = "HOMEPAGE_SLIDER_DETAILS_SUCCESS";
export const HOMEPAGE_SLIDER_DETAILS_FAIL = "HOMEPAGE_SLIDER_DETAILS_FAIL";

export const HOMEPAGE_SLIDER_SAVE_REQUEST = "HOMEPAGE_SLIDER_SAVE_REQUEST";
export const HOMEPAGE_SLIDER_SAVE_SUCCESS = "HOMEPAGE_SLIDER_SAVE_SUCCESS";
export const HOMEPAGE_SLIDER_SAVE_FAIL = "HOMEPAGE_SLIDER_SAVE_FAIL";

export const HOMEPAGE_SLIDER_DELETE_REQUEST = "HOMEPAGE_SLIDER_DELETE_REQUEST";
export const HOMEPAGE_SLIDER_DELETE_SUCCESS = "HOMEPAGE_SLIDER_DELETE_SUCCESS";
export const HOMEPAGE_SLIDER_DELETE_FAIL = "HOMEPAGE_SLIDER_DELETE_FAIL";

  