export const SUBMENU_BEST_PRACTICE_LIST_REQUEST = "SUBMENU_BEST_PRACTICE_LIST_REQUEST";
export const SUBMENU_BEST_PRACTICE_LIST_SUCCESS = "SUBMENU_BEST_PRACTICE_LIST_SUCCESS";
export const SUBMENU_BEST_PRACTICE_LIST_FAIL = "SUBMENU_BEST_PRACTICE_LIST_FAIL";

export const SUBMENU_BEST_PRACTICE_DETAILS_REQUEST = "SUBMENU_BEST_PRACTICE_DETAILS_REQUEST";
export const SUBMENU_BEST_PRACTICE_DETAILS_SUCCESS = "SUBMENU_BEST_PRACTICE_DETAILS_SUCCESS";
export const SUBMENU_BEST_PRACTICE_DETAILS_FAIL = "SUBMENU_BEST_PRACTICE_DETAILS_FAIL";

export const SUBMENU_BEST_PRACTICE_SAVE_REQUEST = "SUBMENU_BEST_PRACTICE_SAVE_REQUEST";
export const SUBMENU_BEST_PRACTICE_SAVE_SUCCESS = "SUBMENU_BEST_PRACTICE_SAVE_SUCCESS";
export const SUBMENU_BEST_PRACTICE_SAVE_FAIL = "SUBMENU_BEST_PRACTICE_SAVE_FAIL";

export const SUBMENU_BEST_PRACTICE_DELETE_REQUEST = "SUBMENU_BEST_PRACTICE_DELETE_REQUEST";
export const SUBMENU_BEST_PRACTICE_DELETE_SUCCESS = "SUBMENU_BEST_PRACTICE_DELETE_SUCCESS";
export const SUBMENU_BEST_PRACTICE_DELETE_FAIL = "SUBMENU_BEST_PRACTICE_DELETE_FAIL";

