import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import Controls from "../../../../components/controls/Controls";
import Loading from "../../../../components/Loading/Loading";
import Notification from "../../../../components/Notification/Notification";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import {
	saveCtaCategoryDocument,
	saveCtaCategory,
} from "../../../../redux/actions/ctaCategoryActions";
import {ResponseMessage} from "../../../../themes/responseMessage";

const RequestProposalScreen = () => {
	const userSignIn = useSelector((state) => state.userSignin);
	//eslint-disable-next-line
	const {userInfo} = userSignIn;

	const ctaCategorySave = useSelector((state) => state.ctaCategorySave);
	const {
		loading: loadingCtaCategorySave,
		success: successCtaCategorySave,
		error: errorCtaCategorySave,
		ctaCategory: ctaCategorySaveData,
	} = ctaCategorySave;

	const ctaCategoryDocumentSave = useSelector(
		(state) => state?.ctaCategoryDocumentSave
	);
	const {
		loading: loadingCtaCategoryDocumentSave,
		success: successCtaCategoryDocumentSave,
		error: errorCtaCategoryDocumentSave,
		ctaCategoryDocument: ctaCategoryDocumentSaveData,
	} = ctaCategoryDocumentSave;

	const ctaCategoryDocumentDelete = useSelector(
		(state) => state?.ctaCategoryDocumentDelete
	);
	const {
		loading: loadingCtaCategoryDocumentDelete,
		success: successCtaCategoryDocumentDelete,
		error: errorCtaCategoryDocumentDelete,
		ctaCategoryDocument: ctaCategoryDocumentDeleteData,
	} = ctaCategoryDocumentDelete || {}

	return <div>RequestProposal Screen</div>;
};

export default RequestProposalScreen;
