// hard coded to both frontend and backend
const ctaFunctionStatus = [
    {
        title: 'Requested',
        value:1,
        id:1,
    },
    {
        title: 'Inprogress',
        value:2,
        id:2,

    },
    {
        title: 'Done',
        value:3,
        id:3,
    },
]

export { ctaFunctionStatus };
