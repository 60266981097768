export const TECH_CATEGORY_LIST_REQUEST = "TECH_CATEGORY_LIST_REQUEST";
export const TECH_CATEGORY_LIST_SUCCESS = "TECH_CATEGORY_LIST_SUCCESS";
export const TECH_CATEGORY_LIST_FAIL = "TECH_CATEGORY_LIST_FAIL";

export const TECH_CATEGORY_DETAILS_REQUEST = "TECH_CATEGORY_DETAILS_REQUEST";
export const TECH_CATEGORY_DETAILS_SUCCESS = "TECH_CATEGORY_DETAILS_SUCCESS";
export const TECH_CATEGORY_DETAILS_FAIL = "TECH_CATEGORY_DETAILS_FAIL";

export const TECH_CATEGORY_SAVE_REQUEST = "TECH_CATEGORY_SAVE_REQUEST";
export const TECH_CATEGORY_SAVE_SUCCESS = "TECH_CATEGORY_SAVE_SUCCESS";
export const TECH_CATEGORY_SAVE_FAIL = "TECH_CATEGORY_SAVE_FAIL";

export const TECH_CATEGORY_DELETE_REQUEST = "TECH_CATEGORY_DELETE_REQUEST";
export const TECH_CATEGORY_DELETE_SUCCESS = "TECH_CATEGORY_DELETE_SUCCESS";
export const TECH_CATEGORY_DELETE_FAIL = "TECH_CATEGORY_DELETE_FAIL";

