export const PERSONALIZED_SERVICE_DETAIL_LIST_REQUEST = "PERSONALIZED_SERVICE_DETAIL_LIST_REQUEST";
export const PERSONALIZED_SERVICE_DETAIL_LIST_SUCCESS = "PERSONALIZED_SERVICE_DETAIL_LIST_SUCCESS";
export const PERSONALIZED_SERVICE_DETAIL_LIST_FAIL = "PERSONALIZED_SERVICE_DETAIL_LIST_FAIL";

export const PERSONALIZED_SERVICE_DETAIL_DETAILS_REQUEST = "PERSONALIZED_SERVICE_DETAIL_DETAILS_REQUEST";
export const PERSONALIZED_SERVICE_DETAIL_DETAILS_SUCCESS = "PERSONALIZED_SERVICE_DETAIL_DETAILS_SUCCESS";
export const PERSONALIZED_SERVICE_DETAIL_DETAILS_FAIL = "PERSONALIZED_SERVICE_DETAIL_DETAILS_FAIL";

export const PERSONALIZED_SERVICE_DETAIL_SAVE_REQUEST = "PERSONALIZED_SERVICE_DETAIL_SAVE_REQUEST";
export const PERSONALIZED_SERVICE_DETAIL_SAVE_SUCCESS = "PERSONALIZED_SERVICE_DETAIL_SAVE_SUCCESS";
export const PERSONALIZED_SERVICE_DETAIL_SAVE_FAIL = "PERSONALIZED_SERVICE_DETAIL_SAVE_FAIL";

export const PERSONALIZED_SERVICE_DETAIL_DELETE_REQUEST = "PERSONALIZED_SERVICE_DETAIL_DELETE_REQUEST";
export const PERSONALIZED_SERVICE_DETAIL_DELETE_SUCCESS = "PERSONALIZED_SERVICE_DETAIL_DELETE_SUCCESS";
export const PERSONALIZED_SERVICE_DETAIL_DELETE_FAIL = "PERSONALIZED_SERVICE_DETAIL_DELETE_FAIL";

  