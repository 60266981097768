export const TECH_STACK_LIST_REQUEST = "TECH_STACK_LIST_REQUEST";
export const TECH_STACK_LIST_SUCCESS = "TECH_STACK_LIST_SUCCESS";
export const TECH_STACK_LIST_FAIL = "TECH_STACK_LIST_FAIL";

export const TECH_STACK_DETAILS_REQUEST = "TECH_STACK_DETAILS_REQUEST";
export const TECH_STACK_DETAILS_SUCCESS = "TECH_STACK_DETAILS_SUCCESS";
export const TECH_STACK_DETAILS_FAIL = "TECH_STACK_DETAILS_FAIL";

export const TECH_STACK_SAVE_REQUEST = "TECH_STACK_SAVE_REQUEST";
export const TECH_STACK_SAVE_SUCCESS = "TECH_STACK_SAVE_SUCCESS";
export const TECH_STACK_SAVE_FAIL = "TECH_STACK_SAVE_FAIL";

export const TECH_STACK_DELETE_REQUEST = "TECH_STACK_DELETE_REQUEST";
export const TECH_STACK_DELETE_SUCCESS = "TECH_STACK_DELETE_SUCCESS";
export const TECH_STACK_DELETE_FAIL = "TECH_STACK_DELETE_FAIL";

  