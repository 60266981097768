export const CTA_CATEGORY_LIST_REQUEST = "CTA_CATEGORY_LIST_REQUEST";
export const CTA_CATEGORY_LIST_SUCCESS = "CTA_CATEGORY_LIST_SUCCESS";
export const CTA_CATEGORY_LIST_FAIL = "CTA_CATEGORY_LIST_FAIL";

export const CTA_CATEGORY_DETAILS_REQUEST = "CTA_CATEGORY_DETAILS_REQUEST";
export const CTA_CATEGORY_DETAILS_SUCCESS = "CTA_CATEGORY_DETAILS_SUCCESS";
export const CTA_CATEGORY_DETAILS_FAIL = "CTA_CATEGORY_DETAILS_FAIL";

export const CTA_CATEGORY_SAVE_REQUEST = "CTA_CATEGORY_SAVE_REQUEST";
export const CTA_CATEGORY_SAVE_SUCCESS = "CTA_CATEGORY_SAVE_SUCCESS";
export const CTA_CATEGORY_SAVE_FAIL = "CTA_CATEGORY_SAVE_FAIL";

export const CTA_CATEGORY_DELETE_REQUEST = "CTA_CATEGORY_DELETE_REQUEST";
export const CTA_CATEGORY_DELETE_SUCCESS = "CTA_CATEGORY_DELETE_SUCCESS";
export const CTA_CATEGORY_DELETE_FAIL = "CTA_CATEGORY_DELETE_FAIL";

export const CTA_CATEGORY_MODEL_LIST_REQUEST =
	"CTA_CATEGORY_MODEL_LIST_REQUEST";
export const CTA_CATEGORY_MODEL_LIST_SUCCESS =
	"CTA_CATEGORY_MODEL_LIST_SUCCESS";
export const CTA_CATEGORY_MODEL_LIST_FAIL = "CTA_CATEGORY_MODEL_LIST_FAIL";

export const CTA_CATEGORY_DOCUMENT_SAVE_REQUEST =
	"CTA_CATEGORY_DOCUMENT_SAVE_REQUEST";
export const CTA_CATEGORY_DOCUMENT_SAVE_SUCCESS =
	"CTA_CATEGORY_DOCUMENT_SAVE_SUCCESS";
export const CTA_CATEGORY_DOCUMENT_SAVE_FAIL =
	"CTA_CATEGORY_DOCUMENT_SAVE_FAIL";

export const CTA_CATEGORY_DOCUMENT_DELETE_REQUEST =
	"CTA_CATEGORY_DOCUMENT_DELETE_REQUEST";
export const CTA_CATEGORY_DOCUMENT_DELETE_SUCCESS =
	"CTA_CATEGORY_DOCUMENT_DELETE_SUCCESS";
export const CTA_CATEGORY_DOCUMENT_DELETE_FAIL =
	"CTA_CATEGORY_DOCUMENT_DELETE_FAIL";
