export const CTA_HOUR_LIST_REQUEST = "CTA_HOUR_LIST_REQUEST";
export const CTA_HOUR_LIST_SUCCESS = "CTA_HOUR_LIST_SUCCESS";
export const CTA_HOUR_LIST_FAIL = "CTA_HOUR_LIST_FAIL";

export const CTA_HOUR_DETAILS_REQUEST = "CTA_HOUR_DETAILS_REQUEST";
export const CTA_HOUR_DETAILS_SUCCESS = "CTA_HOUR_DETAILS_SUCCESS";
export const CTA_HOUR_DETAILS_FAIL = "CTA_HOUR_DETAILS_FAIL";

export const CTA_HOUR_SAVE_REQUEST = "CTA_HOUR_SAVE_REQUEST";
export const CTA_HOUR_SAVE_SUCCESS = "CTA_HOUR_SAVE_SUCCESS";
export const CTA_HOUR_SAVE_FAIL = "CTA_HOUR_SAVE_FAIL";

export const CTA_HOUR_DELETE_REQUEST = "CTA_HOUR_DELETE_REQUEST";
export const CTA_HOUR_DELETE_SUCCESS = "CTA_HOUR_DELETE_SUCCESS";
export const CTA_HOUR_DELETE_FAIL = "CTA_HOUR_DELETE_FAIL";

  