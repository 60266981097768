export const ROLE_RESOURCE_LIST_REQUEST = "ROLE_RESOURCE_LIST_REQUEST";
export const ROLE_RESOURCE_LIST_SUCCESS = "ROLE_RESOURCE_LIST_SUCCESS";
export const ROLE_RESOURCE_LIST_FAIL = "ROLE_RESOURCE_LIST_FAIL";

export const ROLE_RESOURCE_DETAILS_REQUEST = "ROLE_RESOURCE_DETAILS_REQUEST";
export const ROLE_RESOURCE_DETAILS_SUCCESS = "ROLE_RESOURCE_DETAILS_SUCCESS";
export const ROLE_RESOURCE_DETAILS_FAIL = "ROLE_RESOURCE_DETAILS_FAIL";

export const ROLE_RESOURCE_SAVE_REQUEST = "ROLE_RESOURCE_SAVE_REQUEST";
export const ROLE_RESOURCE_SAVE_SUCCESS = "ROLE_RESOURCE_SAVE_SUCCESS";
export const ROLE_RESOURCE_SAVE_FAIL = "ROLE_RESOURCE_SAVE_FAIL";

export const ROLE_RESOURCE_DELETE_REQUEST = "ROLE_RESOURCE_DELETE_REQUEST";
export const ROLE_RESOURCE_DELETE_SUCCESS = "ROLE_RESOURCE_DELETE_SUCCESS";
export const ROLE_RESOURCE_DELETE_FAIL = "ROLE_RESOURCE_DELETE_FAIL";

  