import React, { useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { config } from '../../../config';

const useStyles = makeStyles(theme => ({
    customParagraph: {
        ...theme?.customParagraph
    },
    pdfContainer: {
        marginTop: 20,
    },
}));


const JobSeekerInformationDetailsScreen = (props) => {
    const { recordForEdit } = props
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })

    // Assuming your base URL is http://example.com
    const baseUrl = config.BASE_URL_FOR_FILE;

    // Construct the full URL
    const fullUrl = `${baseUrl}/${recordForEdit.fileUrl}`;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper style={{ overflow: "auto", backgroundColor: "transparent", marginBottom: 20, padding: 20 }}>
                    <Grid container>
                        <Grid item md={6} sm={12}>
                            <Typography variant="h4" paragraph className={classes.customParagraph}><strong>ID:</strong> {recordForEdit.id}</Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}><strong>Job Information ID:</strong> {recordForEdit.jobInformationId}</Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}><strong>Name:</strong> {recordForEdit.name}</Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}><strong>Address:</strong> {recordForEdit.address}</Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}>
                                <strong>linkedIn Link:</strong>{' '}
                                {recordForEdit.linkedInURL && (
                                    <a href={recordForEdit.linkedInURL} target="_blank" rel="noopener noreferrer">
                                        {recordForEdit.linkedInURL}
                                    </a>
                                )}
                            </Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}>
                                <strong>website Link:</strong>{' '}
                                {recordForEdit.websiteURL && (
                                    <a href={recordForEdit.websiteURL} target="_blank" rel="noopener noreferrer">
                                        {recordForEdit.websiteURL}
                                    </a>
                                )}
                            </Typography>


                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Typography variant="h4" paragraph className={classes.customParagraph}><strong>Email:</strong> {recordForEdit.email}</Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}><strong>Phone:</strong> {recordForEdit.phone}</Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}>
                                <strong>File URL:</strong> <a href={fullUrl} target="_blank" rel="noopener noreferrer">Open PDF</a>
                            </Typography>

                            <Typography variant="h4" paragraph className={classes.customParagraph}><strong>Cover Letter:</strong> {recordForEdit.coverLetter}</Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}><strong>Job Title:</strong> {recordForEdit.jobTitle}</Typography>
                            <Typography variant="h4" paragraph className={classes.customParagraph}>
                                <strong>GitHub Link:</strong>{' '}
                                {recordForEdit.gItHubURL && (
                                    <a href={recordForEdit.gItHubURL} target="_blank" rel="noopener noreferrer">
                                        {recordForEdit.gItHubURL}
                                    </a>
                                )}
                            </Typography>
                            {/* Add more fields as needed */}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default JobSeekerInformationDetailsScreen;
