export const HOME_CONSULTATION_TOPIC_LIST_REQUEST = "HOME_CONSULTATION_TOPIC_LIST_REQUEST";
export const HOME_CONSULTATION_TOPIC_LIST_SUCCESS = "HOME_CONSULTATION_TOPIC_LIST_SUCCESS";
export const HOME_CONSULTATION_TOPIC_LIST_FAIL = "HOME_CONSULTATION_TOPIC_LIST_FAIL";

export const HOME_CONSULTATION_TOPIC_DETAILS_REQUEST = "HOME_CONSULTATION_TOPIC_DETAILS_REQUEST";
export const HOME_CONSULTATION_TOPIC_DETAILS_SUCCESS = "HOME_CONSULTATION_TOPIC_DETAILS_SUCCESS";
export const HOME_CONSULTATION_TOPIC_DETAILS_FAIL = "HOME_CONSULTATION_TOPIC_DETAILS_FAIL";

export const HOME_CONSULTATION_TOPIC_SAVE_REQUEST = "HOME_CONSULTATION_TOPIC_SAVE_REQUEST";
export const HOME_CONSULTATION_TOPIC_SAVE_SUCCESS = "HOME_CONSULTATION_TOPIC_SAVE_SUCCESS";
export const HOME_CONSULTATION_TOPIC_SAVE_FAIL = "HOME_CONSULTATION_TOPIC_SAVE_FAIL";

export const HOME_CONSULTATION_TOPIC_DELETE_REQUEST = "HOME_CONSULTATION_TOPIC_DELETE_REQUEST";
export const HOME_CONSULTATION_TOPIC_DELETE_SUCCESS = "HOME_CONSULTATION_TOPIC_DELETE_SUCCESS";
export const HOME_CONSULTATION_TOPIC_DELETE_FAIL = "HOME_CONSULTATION_TOPIC_DELETE_FAIL";

