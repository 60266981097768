export const CTA_PACKAGE_DAILY_LIST_REQUEST = "CTA_PACKAGE_DAILY_LIST_REQUEST";
export const CTA_PACKAGE_DAILY_LIST_SUCCESS = "CTA_PACKAGE_DAILY_LIST_SUCCESS";
export const CTA_PACKAGE_DAILY_LIST_FAIL = "CTA_PACKAGE_DAILY_LIST_FAIL";

export const CTA_PACKAGE_DAILY_DETAILS_REQUEST = "CTA_PACKAGE_DAILY_DETAILS_REQUEST";
export const CTA_PACKAGE_DAILY_DETAILS_SUCCESS = "CTA_PACKAGE_DAILY_DETAILS_SUCCESS";
export const CTA_PACKAGE_DAILY_DETAILS_FAIL = "CTA_PACKAGE_DAILY_DETAILS_FAIL";

export const CTA_PACKAGE_DAILY_SAVE_REQUEST = "CTA_PACKAGE_DAILY_SAVE_REQUEST";
export const CTA_PACKAGE_DAILY_SAVE_SUCCESS = "CTA_PACKAGE_DAILY_SAVE_SUCCESS";
export const CTA_PACKAGE_DAILY_SAVE_FAIL = "CTA_PACKAGE_DAILY_SAVE_FAIL";

export const CTA_PACKAGE_DAILY_DELETE_REQUEST = "CTA_PACKAGE_DAILY_DELETE_REQUEST";
export const CTA_PACKAGE_DAILY_DELETE_SUCCESS = "CTA_PACKAGE_DAILY_DELETE_SUCCESS";
export const CTA_PACKAGE_DAILY_DELETE_FAIL = "CTA_PACKAGE_DAILY_DELETE_FAIL";

  