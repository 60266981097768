export const CONTACT_US_MESSEAGE_LIST_REQUEST = "CONTACT_US_MESSEAGE_LIST_REQUEST";
export const CONTACT_US_MESSEAGE_LIST_SUCCESS = "CONTACT_US_MESSEAGE_LIST_SUCCESS";
export const CONTACT_US_MESSEAGE_LIST_FAIL = "CONTACT_US_MESSEAGE_LIST_FAIL";

export const CONTACT_US_MESSEAGE_DETAILS_REQUEST = "CONTACT_US_MESSEAGE_DETAILS_REQUEST";
export const CONTACT_US_MESSEAGE_DETAILS_SUCCESS = "CONTACT_US_MESSEAGE_DETAILS_SUCCESS";
export const CONTACT_US_MESSEAGE_DETAILS_FAIL = "CONTACT_US_MESSEAGE_DETAILS_FAIL";

export const CONTACT_US_MESSEAGE_SAVE_REQUEST = "CONTACT_US_MESSEAGE_SAVE_REQUEST";
export const CONTACT_US_MESSEAGE_SAVE_SUCCESS = "CONTACT_US_MESSEAGE_SAVE_SUCCESS";
export const CONTACT_US_MESSEAGE_SAVE_FAIL = "CONTACT_US_MESSEAGE_SAVE_FAIL";

export const CONTACT_US_MESSEAGE_DELETE_REQUEST = "CONTACT_US_MESSEAGE_DELETE_REQUEST";
export const CONTACT_US_MESSEAGE_DELETE_SUCCESS = "CONTACT_US_MESSEAGE_DELETE_SUCCESS";
export const CONTACT_US_MESSEAGE_DELETE_FAIL = "CONTACT_US_MESSEAGE_DELETE_FAIL";
