export const CTA_PURCHASE_HISTORY_SAVE_REQUEST =
	"CTA_PURCHASE_HISTORY_SAVE_REQUEST";
export const CTA_PURCHASE_HISTORY_SAVE_SUCCESS =
	"CTA_PURCHASE_HISTORY_SAVE_SUCCESS";
export const CTA_PURCHASE_HISTORY_SAVE_FAIL = "CTA_PURCHASE_HISTORY_SAVE_FAIL";
export const CTA_PURCHASE_HISTORY_PACKAGE_UPDATE_REQUEST =
	"CTA_PURCHASE_HISTORY_PACKAGE_UPDATE_REQUEST";
export const CTA_PURCHASE_HISTORY_PACKAGE_UPDATE_SUCCESS =
	"CTA_PURCHASE_HISTORY_PACKAGE_UPDATE_SUCCESS";
export const CTA_PURCHASE_HISTORY_PACKAGE_UPDATE_FAIL =
	"CTA_PURCHASE_HISTORY_PACKAGE_UPDATE_FAIL";
