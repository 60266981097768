import {
    JOB_LIST_REQUEST,
    JOB_LIST_SUCCESS,
    JOB_LIST_FAIL,
    JOB_DETAILS_REQUEST,
    JOB_DETAILS_SUCCESS,
    JOB_DETAILS_FAIL,
    JOB_SAVE_REQUEST,
    JOB_SAVE_SUCCESS,
    JOB_SAVE_FAIL,
    JOB_DELETE_REQUEST,
    JOB_DELETE_SUCCESS,
    JOB_DELETE_FAIL

} from '../constants/jobConstants';
import { axiosWithoutToken, axiosWithToken, axiosWithTokenAndMultipartData } from '../../helpers/axios';

import { config } from "../../config";

const BASE_API_URL = config.BASE_API_URL


const listJobs = () => async (dispatch) => {
    try {
        dispatch({ type: JOB_LIST_REQUEST });
        const { data } = await axiosWithoutToken.get(`${BASE_API_URL}/BpJobInformation`);

        if (data.status === true) {
            dispatch({ type: JOB_LIST_SUCCESS, payload: data.data ? data.data : [] });
        } else {
            dispatch({ type: JOB_LIST_FAIL, payload: data.message });
        }
        // console.log(data.data)
    }
    catch (error) {
        dispatch({ type: JOB_LIST_FAIL, payload: error.message });

    }
};


const detailsJob = (id) => async (dispatch) => {
    try {
        dispatch({ type: JOB_DETAILS_REQUEST });
        const { data } = await axiosWithoutToken.get("/BpJobInformation/detail/" + id);
        dispatch({ type: JOB_DETAILS_SUCCESS, payload: data });
    }
    catch (error) {
        dispatch({ type: JOB_DETAILS_FAIL, payload: error.message });
    }
};

const saveJobPost = (item, id) => async (dispatch) => {
    try {
        dispatch({ type: JOB_SAVE_REQUEST, payload: item })
        if (!id) {
            console.log('create')
            //eslint-disable-next-line
            const formatData = delete item.id;
            const { data } = await axiosWithTokenAndMultipartData.post("/BpJobInformation/Create", item)
            if (data.status === true) {
                dispatch({ type: JOB_SAVE_SUCCESS, payload: data });
            } else {
                dispatch({ type: JOB_SAVE_FAIL, payload: data.message });
            }
        } else {
            console.log('update')
            const { data } = await axiosWithTokenAndMultipartData.post("/BpJobInformation/Update", item);
            if (data.status === true) {
                dispatch({ type: JOB_SAVE_SUCCESS, payload: data });
            } else {
                dispatch({ type: JOB_SAVE_FAIL, payload: data.message });
            }
        }
    } catch (error) {
        console.log(error)
        dispatch({ type: JOB_SAVE_FAIL, payload: error.message });
    }
};

const deleteJobPost = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOB_DELETE_REQUEST });
        const { data } = await axiosWithToken.delete("/BpJobInformation/" + id);
        if (data.status === true) {
            dispatch({ type: JOB_DELETE_SUCCESS, payload: data, success: true });
        } else {
            dispatch({ type: JOB_DELETE_FAIL, payload: data.message });
        }
    }
    catch (error) {
        dispatch({ type: JOB_DELETE_FAIL, payload: error.message });
    }
};

export { saveJobPost, deleteJobPost, listJobs, detailsJob }