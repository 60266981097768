import React, { useEffect, useState } from 'react';
import {
    Divider,
    Grid,
    Paper,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import useTable from "../../../components/UseTable/useTable";
// import JobDetailForm from "./JobDetailForm";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Loading from "../../../components/Loading/Loading";
import Notification from "../../../components/Notification/Notification";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";
import { ResponseMessage } from "../../../themes/responseMessage";


import { useDispatch, useSelector } from "react-redux";

import { usePermission } from "../../../components/UsePermission/usePermission";
import { accessDeniedRoute } from "../../../routes/routeConstants";
import DetailsIcon from "@material-ui/icons/Details";
// Adjust the following import statements based on your Redux actions
import {
    deleteJobSeekerInfo,
    listJobSeekerInfo,
    saveJobSeekerInfo
} from "../../../redux/actions/jobSeekerAction";

import { config } from "../../../config";
import JobSeekerInformationDetailsScreen from './JobSeekerInformationDetailsScreen';
const BASE_ROOT_URL = config.BASE_ROOT_URL;


const headCells = [
    // Define your table head cells based on the data you want to display
    { id: "jobInformationId", label: "Job Information Id" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    // Add more as needed
    { id: "actions", label: "Actions", disableSorting: true },
];

const JobSeekerInformationScreen = () => {
    const baseUrl = config.BASE_URL_FOR_FILE;



    // Permission logic
    const {
        permission,
        setPermission,
        recievedPermission,
        loadingRoleResource,
        history,
        initialPermission,
    } = usePermission();
    const { createOperation, readOperation, updateOperation, deleteOperation } =
        permission;

    // Redux state and dispatch
    const dispatch = useDispatch();

    const jobSeekerList = useSelector((state) => state.jobSeekerList);
    console.log('jobSeekerList', jobSeekerList)
    const { jobSeekerInfo, loading, error } = jobSeekerList;
    const jobSeekerSave = useSelector((state) => state.jobSeekerSave);
    const {
        loading: loadingSave,
        success: successSave,
        error: errorSave,
    } = jobSeekerSave;

    const jobSeekerDelete = useSelector((state) => state.jobSeekerDelete);
    const {
        loading: loadingDelete,
        success: successDelete,
        error: errorDelete,
    } = jobSeekerDelete;

    const [recordForEdit, setRecordForEdit] = useState(null);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => {
            return items;
        },
    });

    const [openPopup, setOpenPopup] = useState(false);
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    // Define the structure of your table using the useTable hook
    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
        useTable(jobSeekerInfo, headCells, filterFn);

    // Define the add/update promise
    const saveItem = (item, id) =>
        new Promise((resolve, reject) => {
            dispatch(saveJobSeekerInfo(item, id));
            resolve();
        });

    // Define the delete promise
    const deleteItem = (id) =>
        new Promise((resolve, reject) => {
            dispatch(deleteJobSeekerInfo(id));
            resolve();
        });

    // Handle the add or edit operation
    const addOrEdit = async (item, resetForm) => {
        // Adjust the FormData creation based on your form fields
        const formData = new FormData();
        item.id && formData.append("Id", item.id);
        formData.append("Name", item.name);
        formData.append("Email", item.email);
        // Add more fields as needed

        if (formData) {
            resetForm();
            setRecordForEdit(null);
            setOpenPopup(false);
            saveItem(formData, item.id).then(() => {
                if (successSave) {
                    setNotify({
                        isOpen: true,
                        message: "Submitted Successfully",
                        type: "success",
                    });
                }

                if (errorSave) {
                    setNotify({
                        isOpen: true,
                        message: "Submission Failed",
                        type: "warning",
                    });
                }
            });
        }
    };

    // Handle opening the popup for editing
    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);

    };

    // Handle delete operation
    const onDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteItem(id).then(() => {
            if (successDelete) {
                setNotify({
                    isOpen: true,
                    message: "Deleted Successfully",
                    type: "success",
                });
            }
            if (errorDelete) {
                setNotify({
                    isOpen: true,
                    message: ResponseMessage.errorDeleteMessage,
                    type: "warning",
                });
            }
        });
    };

    // useEffect for initial setup and cleanup
    useEffect(() => {
        try {
            // Set permissions if received
            if (recievedPermission) {
                setPermission({ ...recievedPermission });
            }

            // Dispatch listJobSeekerInfo action if readOperation is allowed
            if (recievedPermission?.readOperation) {
                dispatch(listJobSeekerInfo());
            }

            // Redirect to access denied route if readOperation is not allowed
            if (readOperation === false) {
                history.push(accessDeniedRoute);
            }

            // Set permissions to initialPermission if loading is complete
            if (loadingRoleResource === false && !recievedPermission) {
                setPermission({ ...initialPermission });
            }
        } catch (e) {
            console.log(e);
        }
        return () => {
            // Cleanup logic if needed
        };
    }, [
        dispatch,
        successSave,
        successDelete,
        setPermission,
        recievedPermission,
        readOperation,
        history,
        initialPermission,
        loadingRoleResource,
    ]);

    // Return the JSX structure
    return (
        <>
            {loadingRoleResource ||
                loading ||
                loadingSave ||
                loadingDelete
                ? (
                    <Loading />
                ) : (
                    jobSeekerInfo && jobSeekerInfo.length >= 0 && (
                        <>
                            <PageTitle title="Job Seeker Information" />
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    {openPopup ? (
                                        <Widget
                                            title="Job Seeker Form"
                                            upperTitle
                                            disableWidgetMenu
                                            closePopup={() => {
                                                setOpenPopup(false);
                                                setRecordForEdit(null);
                                            }}
                                            closePopUpButtonText="Go back to list"
                                        >
                                            <Divider style={{ marginBottom: 16 }} />
                                            <JobSeekerInformationDetailsScreen recordForEdit={recordForEdit} />
                                        </Widget>
                                    ) : (
                                        <Widget
                                            title="Job Seeker Information Table"
                                            upperTitle
                                            noBodyPadding
                                            setOpenPopup={setOpenPopup}
                                            setRecordForEdit={setRecordForEdit}
                                            threeDotDisplay={true}
                                            disableWidgetMenu
                                            // addNew={() => {
                                            //     setOpenPopup(true);
                                            //     setRecordForEdit(null);
                                            // }}
                                            createOperation={createOperation}
                                        >
                                            <Paper
                                                style={{ overflow: "auto", backgroundColor: "transparent" }}
                                            >
                                                <TblContainer>
                                                    <TblHead />
                                                    <TableBody>
                                                        {recordsAfterPagingAndSorting().map((item) => (
                                                            <TableRow key={item.id}>
                                                                <TableCell>{item.jobInformationId}</TableCell>
                                                                <TableCell>{item.name}</TableCell>
                                                                <TableCell>{item.email}</TableCell>

                                                                <TableCell>
                                                                    <a href={`${baseUrl}/${item.fileUrl}`} target="_blank" rel="noopener noreferrer">Open PDF</a>
                                                                </TableCell>
                                                                {/* Add more cells as needed */}
                                                                <TableCell>
                                                                    {/* {updateOperation && (
                                                                        <Controls.ActionButton
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                openInPopup(item);
                                                                            }}
                                                                        >
                                                                            <EditOutlinedIcon fontSize="small" />
                                                                        </Controls.ActionButton>
                                                                    )} */}
                                                                    <Controls.ActionButton
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            openInPopup(item);
                                                                        }}
                                                                    >
                                                                        <DetailsIcon fontSize="small" />
                                                                    </Controls.ActionButton>
                                                                    {deleteOperation && (
                                                                        <Controls.ActionButton
                                                                            color="secondary"
                                                                            onClick={() => {
                                                                                setConfirmDialog({
                                                                                    isOpen: true,
                                                                                    title:
                                                                                        "Are you sure to delete this record?",
                                                                                    subTitle:
                                                                                        "You can't undo this operation",
                                                                                    onConfirm: () => {
                                                                                        onDelete(item.id);
                                                                                    },
                                                                                });
                                                                            }}
                                                                        >
                                                                            <CloseIcon fontSize="small" />
                                                                        </Controls.ActionButton>
                                                                    )}
                                                                    {!updateOperation && !deleteOperation && (
                                                                        <>Access Denied</>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </TblContainer>
                                                <TblPagination />
                                            </Paper>
                                            <Notification notify={notify} setNotify={setNotify} />
                                            <ConfirmDialog
                                                confirmDialog={confirmDialog}
                                                setConfirmDialog={setConfirmDialog}
                                            />
                                        </Widget>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    )
                )}
        </>
    );
};

export default JobSeekerInformationScreen;
