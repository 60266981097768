export const TRAINING_CANDIDATE_DESCRIPTOR_LIST_REQUEST = "TRAINING_CANDIDATE_DESCRIPTOR_LIST_REQUEST";
export const TRAINING_CANDIDATE_DESCRIPTOR_LIST_SUCCESS = "TRAINING_CANDIDATE_DESCRIPTOR_LIST_SUCCESS";
export const TRAINING_CANDIDATE_DESCRIPTOR_LIST_FAIL = "TRAINING_CANDIDATE_DESCRIPTOR_LIST_FAIL";

export const TRAINING_CANDIDATE_DESCRIPTOR_DETAILS_REQUEST = "TRAINING_CANDIDATE_DESCRIPTOR_DETAILS_REQUEST";
export const TRAINING_CANDIDATE_DESCRIPTOR_DETAILS_SUCCESS = "TRAINING_CANDIDATE_DESCRIPTOR_DETAILS_SUCCESS";
export const TRAINING_CANDIDATE_DESCRIPTOR_DETAILS_FAIL = "TRAINING_CANDIDATE_DESCRIPTOR_DETAILS_FAIL";

export const TRAINING_CANDIDATE_DESCRIPTOR_SAVE_REQUEST = "TRAINING_CANDIDATE_DESCRIPTOR_SAVE_REQUEST";
export const TRAINING_CANDIDATE_DESCRIPTOR_SAVE_SUCCESS = "TRAINING_CANDIDATE_DESCRIPTOR_SAVE_SUCCESS";
export const TRAINING_CANDIDATE_DESCRIPTOR_SAVE_FAIL = "TRAINING_CANDIDATE_DESCRIPTOR_SAVE_FAIL";

export const TRAINING_CANDIDATE_DESCRIPTOR_DELETE_REQUEST = "TRAINING_CANDIDATE_DESCRIPTOR_DELETE_REQUEST";
export const TRAINING_CANDIDATE_DESCRIPTOR_DELETE_SUCCESS = "TRAINING_CANDIDATE_DESCRIPTOR_DELETE_SUCCESS";
export const TRAINING_CANDIDATE_DESCRIPTOR_DELETE_FAIL = "TRAINING_CANDIDATE_DESCRIPTOR_DELETE_FAIL";

