export const HOME_PAGE_CORE_VALUE_DETAIL_LIST_REQUEST = "HOME_PAGE_CORE_VALUE_DETAIL_LIST_REQUEST";
export const HOME_PAGE_CORE_VALUE_DETAIL_LIST_SUCCESS = "HOME_PAGE_CORE_VALUE_DETAIL_LIST_SUCCESS";
export const HOME_PAGE_CORE_VALUE_DETAIL_LIST_FAIL = "HOME_PAGE_CORE_VALUE_DETAIL_LIST_FAIL";

export const HOME_PAGE_CORE_VALUE_DETAIL_DETAILS_REQUEST = "HOME_PAGE_CORE_VALUE_DETAIL_DETAILS_REQUEST";
export const HOME_PAGE_CORE_VALUE_DETAIL_DETAILS_SUCCESS = "HOME_PAGE_CORE_VALUE_DETAIL_DETAILS_SUCCESS";
export const HOME_PAGE_CORE_VALUE_DETAIL_DETAILS_FAIL = "HOME_PAGE_CORE_VALUE_DETAIL_DETAILS_FAIL";

export const HOME_PAGE_CORE_VALUE_DETAIL_SAVE_REQUEST = "HOME_PAGE_CORE_VALUE_DETAIL_SAVE_REQUEST";
export const HOME_PAGE_CORE_VALUE_DETAIL_SAVE_SUCCESS = "HOME_PAGE_CORE_VALUE_DETAIL_SAVE_SUCCESS";
export const HOME_PAGE_CORE_VALUE_DETAIL_SAVE_FAIL = "HOME_PAGE_CORE_VALUE_DETAIL_SAVE_FAIL";

export const HOME_PAGE_CORE_VALUE_DETAIL_DELETE_REQUEST = "HOME_PAGE_CORE_VALUE_DETAIL_DELETE_REQUEST";
export const HOME_PAGE_CORE_VALUE_DETAIL_DELETE_SUCCESS = "HOME_PAGE_CORE_VALUE_DETAIL_DELETE_SUCCESS";
export const HOME_PAGE_CORE_VALUE_DETAIL_DELETE_FAIL = "HOME_PAGE_CORE_VALUE_DETAIL_DELETE_FAIL";

  