export const COMPANY_TYPE_LIST_REQUEST = "COMPANY_TYPE_LIST_REQUEST";
export const COMPANY_TYPE_LIST_SUCCESS = "COMPANY_TYPE_LIST_SUCCESS";
export const COMPANY_TYPE_LIST_FAIL = "COMPANY_TYPE_LIST_FAIL";

export const COMPANY_TYPE_DETAILS_REQUEST = "COMPANY_TYPE_DETAILS_REQUEST";
export const COMPANY_TYPE_DETAILS_SUCCESS = "COMPANY_TYPE_DETAILS_SUCCESS";
export const COMPANY_TYPE_DETAILS_FAIL = "COMPANY_TYPE_DETAILS_FAIL";

export const COMPANY_TYPE_SAVE_REQUEST = "COMPANY_TYPE_SAVE_REQUEST";
export const COMPANY_TYPE_SAVE_SUCCESS = "COMPANY_TYPE_SAVE_SUCCESS";
export const COMPANY_TYPE_SAVE_FAIL = "COMPANY_TYPE_SAVE_FAIL";

export const COMPANY_TYPE_DELETE_REQUEST = "COMPANY_TYPE_DELETE_REQUEST";
export const COMPANY_TYPE_DELETE_SUCCESS = "COMPANY_TYPE_DELETE_SUCCESS";
export const COMPANY_TYPE_DELETE_FAIL = "COMPANY_TYPE_DELETE_FAIL";

  