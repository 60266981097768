export const MENU_SUBMENU_MAP_DETAIL_LIST_REQUEST = "MENU_SUBMENU_MAP_DETAIL_LIST_REQUEST";
export const MENU_SUBMENU_MAP_DETAIL_LIST_SUCCESS = "MENU_SUBMENU_MAP_DETAIL_LIST_SUCCESS";
export const MENU_SUBMENU_MAP_DETAIL_LIST_FAIL = "MENU_SUBMENU_MAP_DETAIL_LIST_FAIL";

export const MENU_SUBMENU_MAP_DETAIL_DETAILS_REQUEST = "MENU_SUBMENU_MAP_DETAIL_DETAILS_REQUEST";
export const MENU_SUBMENU_MAP_DETAIL_DETAILS_SUCCESS = "MENU_SUBMENU_MAP_DETAIL_DETAILS_SUCCESS";
export const MENU_SUBMENU_MAP_DETAIL_DETAILS_FAIL = "MENU_SUBMENU_MAP_DETAIL_DETAILS_FAIL";

export const MENU_SUBMENU_MAP_DETAIL_SAVE_REQUEST = "MENU_SUBMENU_MAP_DETAIL_SAVE_REQUEST";
export const MENU_SUBMENU_MAP_DETAIL_SAVE_SUCCESS = "MENU_SUBMENU_MAP_DETAIL_SAVE_SUCCESS";
export const MENU_SUBMENU_MAP_DETAIL_SAVE_FAIL = "MENU_SUBMENU_MAP_DETAIL_SAVE_FAIL";

export const MENU_SUBMENU_MAP_DETAIL_DELETE_REQUEST = "MENU_SUBMENU_MAP_DETAIL_DELETE_REQUEST";
export const MENU_SUBMENU_MAP_DETAIL_DELETE_SUCCESS = "MENU_SUBMENU_MAP_DETAIL_DELETE_SUCCESS";
export const MENU_SUBMENU_MAP_DETAIL_DELETE_FAIL = "MENU_SUBMENU_MAP_DETAIL_DELETE_FAIL";

  