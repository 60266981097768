// jobSeekerInformationConstants.js

export const JOB_SEEKER_INFO_LIST_REQUEST = 'JOB_SEEKER_INFO_LIST_REQUEST';
export const JOB_SEEKER_INFO_LIST_SUCCESS = 'JOB_SEEKER_INFO_LIST_SUCCESS';
export const JOB_SEEKER_INFO_LIST_FAIL = 'JOB_SEEKER_INFO_LIST_FAIL';

export const JOB_SEEKER_INFO_DETAILS_REQUEST = 'JOB_SEEKER_INFO_DETAILS_REQUEST';
export const JOB_SEEKER_INFO_DETAILS_SUCCESS = 'JOB_SEEKER_INFO_DETAILS_SUCCESS';
export const JOB_SEEKER_INFO_DETAILS_FAIL = 'JOB_SEEKER_INFO_DETAILS_FAIL';

export const JOB_SEEKER_INFO_SAVE_REQUEST = 'JOB_SEEKER_INFO_SAVE_REQUEST';
export const JOB_SEEKER_INFO_SAVE_SUCCESS = 'JOB_SEEKER_INFO_SAVE_SUCCESS';
export const JOB_SEEKER_INFO_SAVE_FAIL = 'JOB_SEEKER_INFO_SAVE_FAIL';

export const JOB_SEEKER_INFO_DELETE_REQUEST = 'JOB_SEEKER_INFO_DELETE_REQUEST';
export const JOB_SEEKER_INFO_DELETE_SUCCESS = 'JOB_SEEKER_INFO_DELETE_SUCCESS';
export const JOB_SEEKER_INFO_DELETE_FAIL = 'JOB_SEEKER_INFO_DELETE_FAIL';
