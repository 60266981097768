// jobReducers.js

import { 
    JOB_LIST_REQUEST, 
    JOB_LIST_SUCCESS, 
    JOB_LIST_FAIL,
    JOB_DETAILS_REQUEST,
    JOB_DETAILS_SUCCESS,
    JOB_DETAILS_FAIL,
    JOB_SAVE_REQUEST,
    JOB_SAVE_SUCCESS,
    JOB_SAVE_FAIL,
    JOB_DELETE_REQUEST,
    JOB_DELETE_SUCCESS,
    JOB_DELETE_FAIL 
} from '../constants/jobConstants';

function jobListReducer(state={jobs:[]},action){
    switch(action.type){
        case JOB_LIST_REQUEST:
            return { loading:true, jobs:[] };
        case JOB_LIST_SUCCESS:
            return { loading:false, jobs:action.payload };
        case JOB_LIST_FAIL:
            return { loading:false, error: action.payload };
        default:
            return state;
    }
}

function jobDetailsReducer(state={job:{}},action){
    switch(action.type){
        case JOB_DETAILS_REQUEST:
            return { loading:true };
        case JOB_DETAILS_SUCCESS:
            return { loading:false, job:action.payload };
        case JOB_DETAILS_FAIL:
            return { loading:false, error: action.payload };
        default:
            return state;
    }
}

function jobSaveReducer(state={job:{}},action){
    switch(action.type){
        case JOB_SAVE_REQUEST:
            return { loading:true };
        case JOB_SAVE_SUCCESS:
            return { loading:false, success:true, job:action.payload };
        case JOB_SAVE_FAIL:
            return { loading:false, error: action.payload };
        default:
            return state;
    }
}

function jobDeleteReducer(state={job:{}},action){
    switch(action.type){
        case JOB_DELETE_REQUEST:
            return { loading:true };
        case JOB_DELETE_SUCCESS:
            return { loading:false, success:true, job:action.payload };
        case JOB_DELETE_FAIL:
            return { loading:false, error: action.payload };
        default:
            return state;
    }
}

export {  jobListReducer, jobDetailsReducer, jobSaveReducer, jobDeleteReducer };
