export const MODERN_TECH_DETAIL_LIST_REQUEST = "MODERN_TECH_DETAIL_LIST_REQUEST";
export const MODERN_TECH_DETAIL_LIST_SUCCESS = "MODERN_TECH_DETAIL_LIST_SUCCESS";
export const MODERN_TECH_DETAIL_LIST_FAIL = "MODERN_TECH_DETAIL_LIST_FAIL";

export const MODERN_TECH_DETAIL_DETAILS_REQUEST = "MODERN_TECH_DETAIL_DETAILS_REQUEST";
export const MODERN_TECH_DETAIL_DETAILS_SUCCESS = "MODERN_TECH_DETAIL_DETAILS_SUCCESS";
export const MODERN_TECH_DETAIL_DETAILS_FAIL = "MODERN_TECH_DETAIL_DETAILS_FAIL";

export const MODERN_TECH_DETAIL_SAVE_REQUEST = "MODERN_TECH_DETAIL_SAVE_REQUEST";
export const MODERN_TECH_DETAIL_SAVE_SUCCESS = "MODERN_TECH_DETAIL_SAVE_SUCCESS";
export const MODERN_TECH_DETAIL_SAVE_FAIL = "MODERN_TECH_DETAIL_SAVE_FAIL";

export const MODERN_TECH_DETAIL_DELETE_REQUEST = "MODERN_TECH_DETAIL_DELETE_REQUEST";
export const MODERN_TECH_DETAIL_DELETE_SUCCESS = "MODERN_TECH_DETAIL_DELETE_SUCCESS";
export const MODERN_TECH_DETAIL_DELETE_FAIL = "MODERN_TECH_DETAIL_DELETE_FAIL";

  