export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";

export const USER_SAVE_REQUEST = "USER_SAVE_REQUEST";
export const USER_SAVE_SUCCESS = "USER_SAVE_SUCCESS";
export const USER_SAVE_FAIL = "USER_SAVE_FAIL";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";

export const USER_PASSWORD_RECOVERY_REQUEST = "USER_PASSWORD_RECOVERY_REQUEST";
export const USER_PASSWORD_RECOVERY_SUCCESS = "USER_PASSWORD_RECOVERY_SUCCESS";
export const USER_PASSWORD_RECOVERY_FAIL = "USER_PASSWORD_RECOVERY_FAIL";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";

export const USER_ACCEPT_CLIENT_LIST_REQUEST =
	"USER_ACCEPT_CLIENT_LIST_REQUEST";
export const USER_ACCEPT_CLIENT_LIST_SUCCESS =
	"USER_ACCEPT_CLIENT_LIST_SUCCESS";
export const USER_ACCEPT_CLIENT_LIST_FAIL = "USER_ACCEPT_CLIENT_LIST_FAIL";

export const USER_PASSWORD_CHANGE_REQUEST = "USER_PASSWORD_CHANGE_REQUEST";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAIL = "USER_PASSWORD_CHANGE_FAIL";

export const USER_DEACTIVATE_REQUEST = "USER_DEACTIVATE_REQUEST";
export const USER_DEACTIVATE_SUCCESS = "USER_DEACTIVATE_SUCCESS";
export const USER_DEACTIVATE_FAIL = "USER_DEACTIVATE_FAIL";
