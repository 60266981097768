
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_LIST_REQUEST = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_LIST_REQUEST";
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_LIST_SUCCESS = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_LIST_SUCCESS";
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_LIST_FAIL = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_LIST_FAIL";

export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_DETAILS_REQUEST = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_DETAILS_REQUEST";
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_DETAILS_SUCCESS = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_DETAILS_SUCCESS";
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_DETAILS_FAIL = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_DETAILS_FAIL";

export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_SAVE_REQUEST = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_SAVE_REQUEST";
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_SAVE_SUCCESS = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_SAVE_SUCCESS";
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_SAVE_FAIL = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_SAVE_FAIL";

export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_DELETE_REQUEST = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_DELETE_REQUEST";
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_DELETE_SUCCESS = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_DELETE_SUCCESS";
export const SOFTWARE_TRAINING_TYPE_AND_LENGTH_DELETE_FAIL = "SOFTWARE_TRAINING_TYPE_AND_LENGTH_DELETE_FAIL";

  