export const CLIENT_LIST_REQUEST = "CLIENT_LIST_REQUEST";
export const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
export const CLIENT_LIST_FAIL = "CLIENT_LIST_FAIL";

export const CLIENT_DETAILS_REQUEST = "CLIENT_DETAILS_REQUEST";
export const CLIENT_DETAILS_SUCCESS = "CLIENT_DETAILS_SUCCESS";
export const CLIENT_DETAILS_FAIL = "CLIENT_DETAILS_FAIL";

export const CLIENT_SAVE_REQUEST = "CLIENT_SAVE_REQUEST";
export const CLIENT_SAVE_SUCCESS = "CLIENT_SAVE_SUCCESS";
export const CLIENT_SAVE_FAIL = "CLIENT_SAVE_FAIL";

export const CLIENT_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_FAIL = "CLIENT_DELETE_FAIL";
