// jobConstants.js

export const JOB_LIST_REQUEST = 'JOB_LIST_REQUEST';
export const JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS';
export const JOB_LIST_FAIL = 'JOB_LIST_FAIL';

export const JOB_DETAILS_REQUEST = 'JOB_DETAILS_REQUEST';
export const JOB_DETAILS_SUCCESS = 'JOB_DETAILS_SUCCESS';
export const JOB_DETAILS_FAIL = 'JOB_DETAILS_FAIL';

export const JOB_SAVE_REQUEST = 'JOB_SAVE_REQUEST';
export const JOB_SAVE_SUCCESS = 'JOB_SAVE_SUCCESS';
export const JOB_SAVE_FAIL = 'JOB_SAVE_FAIL';

export const JOB_DELETE_REQUEST = 'JOB_DELETE_REQUEST';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_FAIL = 'JOB_DELETE_FAIL';

// Add more constants as needed for other actions related to jobs
