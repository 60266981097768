// jobSeekerInformationReducers.js

import {
    JOB_SEEKER_INFO_LIST_REQUEST,
    JOB_SEEKER_INFO_LIST_SUCCESS,
    JOB_SEEKER_INFO_LIST_FAIL,
    JOB_SEEKER_INFO_DETAILS_REQUEST,
    JOB_SEEKER_INFO_DETAILS_SUCCESS,
    JOB_SEEKER_INFO_DETAILS_FAIL,
    JOB_SEEKER_INFO_SAVE_REQUEST,
    JOB_SEEKER_INFO_SAVE_SUCCESS,
    JOB_SEEKER_INFO_SAVE_FAIL,
    JOB_SEEKER_INFO_DELETE_REQUEST,
    JOB_SEEKER_INFO_DELETE_SUCCESS,
    JOB_SEEKER_INFO_DELETE_FAIL
} from '../constants/jobSeekerConstant.js';

function jobSeekerInfoListReducer(state = { jobSeekerInfo: [] }, action) {
    switch (action.type) {
        case JOB_SEEKER_INFO_LIST_REQUEST:
            return { loading: true, jobSeekerInfo: [] };
        case JOB_SEEKER_INFO_LIST_SUCCESS:
            return { loading: false, jobSeekerInfo: action.payload };
        case JOB_SEEKER_INFO_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

function jobSeekerInfoDetailsReducer(state = { jobSeekerInfo: {} }, action) {
    switch (action.type) {
        case JOB_SEEKER_INFO_DETAILS_REQUEST:
            return { loading: true };
        case JOB_SEEKER_INFO_DETAILS_SUCCESS:
            return { loading: false, jobSeekerInfo: action.payload };
        case JOB_SEEKER_INFO_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

function jobSeekerInfoSaveReducer(state = { jobSeekerInfo: {} }, action) {
    switch (action.type) {
        case JOB_SEEKER_INFO_SAVE_REQUEST:
            return { loading: true };
        case JOB_SEEKER_INFO_SAVE_SUCCESS:
            return { loading: false, success: true, jobSeekerInfo: action.payload };
        case JOB_SEEKER_INFO_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

function jobSeekerInfoDeleteReducer(state = { jobSeekerInfo: {} }, action) {
    switch (action.type) {
        case JOB_SEEKER_INFO_DELETE_REQUEST:
            return { loading: true };
        case JOB_SEEKER_INFO_DELETE_SUCCESS:
            return { loading: false, success: true, jobSeekerInfo: action.payload };
        case JOB_SEEKER_INFO_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export {
    jobSeekerInfoListReducer,
    jobSeekerInfoDetailsReducer,
    jobSeekerInfoSaveReducer,
    jobSeekerInfoDeleteReducer
};
