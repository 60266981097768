export const ROLE_LIST_REQUEST = "ROLE_LIST_REQUEST";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const ROLE_LIST_FAIL = "ROLE_LIST_FAIL";

export const ROLE_DETAILS_REQUEST = "ROLE_DETAILS_REQUEST";
export const ROLE_DETAILS_SUCCESS = "ROLE_DETAILS_SUCCESS";
export const ROLE_DETAILS_FAIL = "ROLE_DETAILS_FAIL";

export const ROLE_SAVE_REQUEST = "ROLE_SAVE_REQUEST";
export const ROLE_SAVE_SUCCESS = "ROLE_SAVE_SUCCESS";
export const ROLE_SAVE_FAIL = "ROLE_SAVE_FAIL";

export const ROLE_DELETE_REQUEST = "ROLE_DELETE_REQUEST";
export const ROLE_DELETE_SUCCESS = "ROLE_DELETE_SUCCESS";
export const ROLE_DELETE_FAIL = "ROLE_DELETE_FAIL";

  