export const SUBSCRIPTION_LIST_REQUEST = "SUBSCRIPTION_LIST_REQUEST";
export const SUBSCRIPTION_LIST_SUCCESS = "SUBSCRIPTION_LIST_SUCCESS";
export const SUBSCRIPTION_LIST_FAIL = "SUBSCRIPTION_LIST_FAIL";

export const SUBSCRIPTION_DETAILS_REQUEST = "SUBSCRIPTION_DETAILS_REQUEST";
export const SUBSCRIPTION_DETAILS_SUCCESS = "SUBSCRIPTION_DETAILS_SUCCESS";
export const SUBSCRIPTION_DETAILS_FAIL = "SUBSCRIPTION_DETAILS_FAIL";

export const SUBSCRIPTION_SAVE_REQUEST = "SUBSCRIPTION_SAVE_REQUEST";
export const SUBSCRIPTION_SAVE_SUCCESS = "SUBSCRIPTION_SAVE_SUCCESS";
export const SUBSCRIPTION_SAVE_FAIL = "SUBSCRIPTION_SAVE_FAIL";

export const SUBSCRIPTION_DELETE_REQUEST = "SUBSCRIPTION_DELETE_REQUEST";
export const SUBSCRIPTION_DELETE_SUCCESS = "SUBSCRIPTION_DELETE_SUCCESS";
export const SUBSCRIPTION_DELETE_FAIL = "SUBSCRIPTION_DELETE_FAIL";

