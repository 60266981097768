export const TRAINING_OVERVIEW_LIST_REQUEST = "TRAINING_OVERVIEW_LIST_REQUEST";
export const TRAINING_OVERVIEW_LIST_SUCCESS = "TRAINING_OVERVIEW_LIST_SUCCESS";
export const TRAINING_OVERVIEW_LIST_FAIL = "TRAINING_OVERVIEW_LIST_FAIL";

export const TRAINING_OVERVIEW_DETAILS_REQUEST = "TRAINING_OVERVIEW_DETAILS_REQUEST";
export const TRAINING_OVERVIEW_DETAILS_SUCCESS = "TRAINING_OVERVIEW_DETAILS_SUCCESS";
export const TRAINING_OVERVIEW_DETAILS_FAIL = "TRAINING_OVERVIEW_DETAILS_FAIL";

export const TRAINING_OVERVIEW_SAVE_REQUEST = "TRAINING_OVERVIEW_SAVE_REQUEST";
export const TRAINING_OVERVIEW_SAVE_SUCCESS = "TRAINING_OVERVIEW_SAVE_SUCCESS";
export const TRAINING_OVERVIEW_SAVE_FAIL = "TRAINING_OVERVIEW_SAVE_FAIL";

export const TRAINING_OVERVIEW_DELETE_REQUEST = "TRAINING_OVERVIEW_DELETE_REQUEST";
export const TRAINING_OVERVIEW_DELETE_SUCCESS = "TRAINING_OVERVIEW_DELETE_SUCCESS";
export const TRAINING_OVERVIEW_DELETE_FAIL = "TRAINING_OVERVIEW_DELETE_FAIL";

