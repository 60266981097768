export const HOMEPAGE_LIST_REQUEST = "HOMEPAGE_LIST_REQUEST";
export const HOMEPAGE_LIST_SUCCESS = "HOMEPAGE_LIST_SUCCESS";
export const HOMEPAGE_LIST_FAIL = "HOMEPAGE_LIST_FAIL";

export const HOMEPAGE_DETAILS_REQUEST = "HOMEPAGE_DETAILS_REQUEST";
export const HOMEPAGE_DETAILS_SUCCESS = "HOMEPAGE_DETAILS_SUCCESS";
export const HOMEPAGE_DETAILS_FAIL = "HOMEPAGE_DETAILS_FAIL";

export const HOMEPAGE_SAVE_REQUEST = "HOMEPAGE_SAVE_REQUEST";
export const HOMEPAGE_SAVE_SUCCESS = "HOMEPAGE_SAVE_SUCCESS";
export const HOMEPAGE_SAVE_FAIL = "HOMEPAGE_SAVE_FAIL";

export const HOMEPAGE_DELETE_REQUEST = "HOMEPAGE_DELETE_REQUEST";
export const HOMEPAGE_DELETE_SUCCESS = "HOMEPAGE_DELETE_SUCCESS";
export const HOMEPAGE_DELETE_FAIL = "HOMEPAGE_DELETE_FAIL";

  