export const SUBMENU_BUSINESS_CONTEXT_LIST_REQUEST = "SUBMENU_BUSINESS_CONTEXT_LIST_REQUEST";
export const SUBMENU_BUSINESS_CONTEXT_LIST_SUCCESS = "SUBMENU_BUSINESS_CONTEXT_LIST_SUCCESS";
export const SUBMENU_BUSINESS_CONTEXT_LIST_FAIL = "SUBMENU_BUSINESS_CONTEXT_LIST_FAIL";

export const SUBMENU_BUSINESS_CONTEXT_DETAILS_REQUEST = "SUBMENU_BUSINESS_CONTEXT_DETAILS_REQUEST";
export const SUBMENU_BUSINESS_CONTEXT_DETAILS_SUCCESS = "SUBMENU_BUSINESS_CONTEXT_DETAILS_SUCCESS";
export const SUBMENU_BUSINESS_CONTEXT_DETAILS_FAIL = "SUBMENU_BUSINESS_CONTEXT_DETAILS_FAIL";

export const SUBMENU_BUSINESS_CONTEXT_SAVE_REQUEST = "SUBMENU_BUSINESS_CONTEXT_SAVE_REQUEST";
export const SUBMENU_BUSINESS_CONTEXT_SAVE_SUCCESS = "SUBMENU_BUSINESS_CONTEXT_SAVE_SUCCESS";
export const SUBMENU_BUSINESS_CONTEXT_SAVE_FAIL = "SUBMENU_BUSINESS_CONTEXT_SAVE_FAIL";

export const SUBMENU_BUSINESS_CONTEXT_DELETE_REQUEST = "SUBMENU_BUSINESS_CONTEXT_DELETE_REQUEST";
export const SUBMENU_BUSINESS_CONTEXT_DELETE_SUCCESS = "SUBMENU_BUSINESS_CONTEXT_DELETE_SUCCESS";
export const SUBMENU_BUSINESS_CONTEXT_DELETE_FAIL = "SUBMENU_BUSINESS_CONTEXT_DELETE_FAIL";

  