import {
    Divider,
    Grid,
    Paper,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import Controls from "../../../components/controls/Controls";
import useTable from "../../../components/UseTable/useTable";
import JobDetailForm from "./JobDetailForm";
// import Popup from "../../../components/Popup/Popup";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Loading from "../../../components/Loading/Loading";
import Notification from "../../../components/Notification/Notification";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";
import { ResponseMessage } from "../../../themes/responseMessage";

import { useDispatch, useSelector } from "react-redux";

// permissions
import { usePermission } from "../../../components/UsePermission/usePermission";
import { accessDeniedRoute } from "../../../routes/routeConstants";
// redux actions
import {
    deleteJobPost,
    listJobs,
    saveJobPost
} from "../../../redux/actions/jobAction";

import { config } from "../../../config";
const BASE_ROOT_URL = config.BASE_ROOT_URL;

const headCells = [
    { id: "id", label: "Id" },
    { id: "jobTitle", label: "Job Title" },
    { id: "categoryName", label: "Category Name" },
    { id: "jobStatus", label: "Job Status" },
    { id: "jobType", label: "Job Type" },
    { id: "location", label: "Location" },
    { id: 'jobDescription', label: 'Job Description' },
    // { id: 'jobOtherDescription', label: 'Job Other Description' },
    // { id: 'aboutComapny', label: 'About Comapny' },
    { id: "actions", label: "Actions", disableSorting: true },
];

export default function JobScreen() {
    // permission get
    const {
        permission,
        setPermission,
        recievedPermission,
        loadingRoleResource,
        history,
        initialPermission,
    } = usePermission();
    const { createOperation, readOperation, updateOperation, deleteOperation } =
        permission;
    // permission get end

    const userSignIn = useSelector((state) => state.userSignin);
    const { userInfo } = userSignIn;

    const jobList = useSelector((state) => state.jobList);
    //eslint-disable-next-line
    const { jobs, loading, error } = jobList;
    const jobSave = useSelector((state) => state.jobSave);
    //eslint-disable-next-line
    const {
        loading: loadingSave,
        success: successSave,
        error: errorSave,
    } = jobSave;
    const jobDelete = useSelector((state) => state.jobDelete);
    //eslint-disable-next-line
    const {
        loading: loadingDelete,
        success: successDelete,
        error: errorDelete,
    } = jobDelete;

    const [recordForEdit, setRecordForEdit] = useState(null);
    // const [records, setRecords] = useState([])
    //eslint-disable-next-line
    const [filterFn, setFilterFn] = useState({
        fn: (items) => {
            return items;
        },
    });
    const [openPopup, setOpenPopup] = useState(false);
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
        useTable(jobs, headCells, filterFn);

    const dispatch = useDispatch();

    // add/update promise
    const saveItem = (item, id) =>
        new Promise((resolve, reject) => {
            dispatch(saveJobPost(item, id));
            resolve();
        });

    // delete promise
    const deleteItem = (id) =>
        new Promise((resolve, reject) => {
            dispatch(deleteJobPost(id));
            resolve();
        });

    const addOrEdit = async (item, resetForm) => {
        const formData = new FormData();
        item.id && formData.append("Id", item.id);
        formData.append("JobTitle", item.jobTitle);
        formData.append("CategoryName", item.categoryName);
        formData.append("JobStatus", item.jobStatus);
        formData.append("JobType", item.jobType);
        formData.append("Location", item.location);
        formData.append("SalaryRange", item.salaryRange);
        formData.append("JobDescription", item.jobDescription);
        formData.append("JobOtherDescription", item.jobOtherDescription);
        formData.append("AboutComapny", item.aboutComapny);
        formData.append("ApplyDeadline", item.applyDeadline);
        formData.append("CareerLevel", item.careerLevel);
        formData.append("OtherBenefit", item.otherBenefit);
        formData.append("WebsiteLink", item.websiteLink);
        formData.append("NumberOfVacancy", item.numberOfVacancy);

        if (formData) {
            resetForm();
            setRecordForEdit(null);
            setOpenPopup(false);
            saveItem(formData, item.id).then(() => {
                if (successSave) {
                    setNotify({
                        isOpen: true,
                        message: "Submitted Successfully",
                        type: "success",
                    });
                }

                if (errorSave) {
                    setNotify({
                        isOpen: true,
                        message: "Submition Failed",
                        type: "warning",
                    });
                }
            });
        }
    };

    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    const onDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteItem(id).then(() => {
            if (successDelete) {
                setNotify({
                    isOpen: true,
                    message: "Deleted Successfully",
                    type: "success",
                });
            }
            if (errorDelete) {
                setNotify({
                    isOpen: true,
                    message: ResponseMessage.errorDeleteMessage,
                    type: "warning",
                });
            }
        });
    };

    useEffect(() => {
        try {
            if (recievedPermission) {
                setPermission({ ...recievedPermission });
            }
            if (recievedPermission?.readOperation) {
                dispatch(listJobs());
            }
            if (readOperation === false) {
                history.push(accessDeniedRoute);
            }
            if (loadingRoleResource === false && !recievedPermission) {
                setPermission({ ...initialPermission });
            }
        } catch (e) {
            console.log(e);
        }
        return () => {
            //
        };
    }, [
        dispatch,
        successSave,
        successDelete,
        setPermission,
        recievedPermission,
        readOperation,
        history,
        initialPermission,
        loadingRoleResource,
    ]);
    return (
        <>
            {loadingRoleResource ||
                loading ||
                loadingSave ||
                loadingDelete
                ? (
                    <Loading />
                ) : (
                    jobs && jobs.length >= 0 && (
                        <>
                            <PageTitle title="Job Posts" />
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    {openPopup ? (
                                        <Widget
                                            title="Blog Post Form"
                                            upperTitle
                                            disableWidgetMenu
                                        >
                                            <Divider style={{ marginBottom: 16 }} />

                                            <JobDetailForm
                                                recordForEdit={recordForEdit}
                                                addOrEdit={addOrEdit}
                                                loadingSave={loadingSave}
                                                setOpenPopup={setOpenPopup}
                                            />
                                        </Widget>
                                    ) : (
                                        <Widget
                                            title="Job Post List Table"
                                            upperTitle
                                            noBodyPadding
                                            setOpenPopup={setOpenPopup}
                                            setRecordForEdit={setRecordForEdit}
                                            threeDotDisplay={true}
                                            disableWidgetMenu
                                            addNew={() => {
                                                setOpenPopup(true);
                                                setRecordForEdit(null);
                                            }}
                                            createOperation={createOperation}
                                        >
                                            <Paper
                                                style={{ overflow: "auto", backgroundColor: "transparent" }}
                                            >
                                                <TblContainer>
                                                    <TblHead />
                                                    <TableBody>
                                                        {recordsAfterPagingAndSorting().map((item) => (
                                                            <TableRow key={item.id}>
                                                                <TableCell>{item.id}</TableCell>
                                                                <TableCell>{item.jobTitle}</TableCell>
                                                                <TableCell>{item.categoryName}</TableCell>
                                                                <TableCell>{item.jobStatus ? "Active" : "Inactive"}</TableCell>
                                                                <TableCell>{item.jobType}</TableCell>
                                                                <TableCell>{item.location}</TableCell>
                                                                {/* <TableCell>{item.jobDescription}</TableCell> */}

                                                                <TableCell><div dangerouslySetInnerHTML={{ __html: `${item?.jobDescription}` }} /></TableCell>


                                                                {/* <TableCell><div dangerouslySetInnerHTML={{ __html: `${item?.jobOtherDescription}` }} /></TableCell>

                                                                <TableCell><div dangerouslySetInnerHTML={{ __html: `${item?.aboutComapny}` }} /></TableCell> */}

                                                                <TableCell>
                                                                    {updateOperation && (
                                                                        <Controls.ActionButton
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                openInPopup(item);
                                                                            }}
                                                                        >
                                                                            <EditOutlinedIcon fontSize="small" />
                                                                        </Controls.ActionButton>
                                                                    )}
                                                                    {deleteOperation && (
                                                                        <Controls.ActionButton
                                                                            color="secondary"
                                                                            onClick={() => {
                                                                                setConfirmDialog({
                                                                                    isOpen: true,
                                                                                    title:
                                                                                        "Are you sure to delete this record?",
                                                                                    subTitle:
                                                                                        "You can't undo this operation",
                                                                                    onConfirm: () => {
                                                                                        onDelete(item.id);
                                                                                    },
                                                                                });
                                                                            }}
                                                                        >
                                                                            <CloseIcon fontSize="small" />
                                                                        </Controls.ActionButton>
                                                                    )}
                                                                    {!updateOperation && !deleteOperation && (
                                                                        <>Access Denied</>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </TblContainer>
                                                <TblPagination />
                                            </Paper>
                                            <Notification notify={notify} setNotify={setNotify} />
                                            <ConfirmDialog
                                                confirmDialog={confirmDialog}
                                                setConfirmDialog={setConfirmDialog}
                                            />
                                        </Widget>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    )
                )}
        </>
    );
}