export const MENU_HERO_SLIDER_LIST_REQUEST = "MENU_HERO_SLIDER_LIST_REQUEST";
export const MENU_HERO_SLIDER_LIST_SUCCESS = "MENU_HERO_SLIDER_LIST_SUCCESS";
export const MENU_HERO_SLIDER_LIST_FAIL = "MENU_HERO_SLIDER_LIST_FAIL";

export const MENU_HERO_SLIDER_DETAILS_REQUEST = "MENU_HERO_SLIDER_DETAILS_REQUEST";
export const MENU_HERO_SLIDER_DETAILS_SUCCESS = "MENU_HERO_SLIDER_DETAILS_SUCCESS";
export const MENU_HERO_SLIDER_DETAILS_FAIL = "MENU_HERO_SLIDER_DETAILS_FAIL";

export const MENU_HERO_SLIDER_SAVE_REQUEST = "MENU_HERO_SLIDER_SAVE_REQUEST";
export const MENU_HERO_SLIDER_SAVE_SUCCESS = "MENU_HERO_SLIDER_SAVE_SUCCESS";
export const MENU_HERO_SLIDER_SAVE_FAIL = "MENU_HERO_SLIDER_SAVE_FAIL";

export const MENU_HERO_SLIDER_DELETE_REQUEST = "MENU_HERO_SLIDER_DELETE_REQUEST";
export const MENU_HERO_SLIDER_DELETE_SUCCESS = "MENU_HERO_SLIDER_DELETE_SUCCESS";
export const MENU_HERO_SLIDER_DELETE_FAIL = "MENU_HERO_SLIDER_DELETE_FAIL";

  