export const BLOG_SUB_CATEGORY_LIST_REQUEST = "BLOG_SUB_CATEGORY_LIST_REQUEST";
export const BLOG_SUB_CATEGORY_LIST_SUCCESS = "BLOG_SUB_CATEGORY_LIST_SUCCESS";
export const BLOG_SUB_CATEGORY_LIST_FAIL = "BLOG_SUB_CATEGORY_LIST_FAIL";

export const BLOG_SUB_CATEGORY_DETAILS_REQUEST = "BLOG_SUB_CATEGORY_DETAILS_REQUEST";
export const BLOG_SUB_CATEGORY_DETAILS_SUCCESS = "BLOG_SUB_CATEGORY_DETAILS_SUCCESS";
export const BLOG_SUB_CATEGORY_DETAILS_FAIL = "BLOG_SUB_CATEGORY_DETAILS_FAIL";

export const BLOG_SUB_CATEGORY_SAVE_REQUEST = "BLOG_SUB_CATEGORY_SAVE_REQUEST";
export const BLOG_SUB_CATEGORY_SAVE_SUCCESS = "BLOG_SUB_CATEGORY_SAVE_SUCCESS";
export const BLOG_SUB_CATEGORY_SAVE_FAIL = "BLOG_SUB_CATEGORY_SAVE_FAIL";

export const BLOG_SUB_CATEGORY_DELETE_REQUEST = "BLOG_SUB_CATEGORY_DELETE_REQUEST";
export const BLOG_SUB_CATEGORY_DELETE_SUCCESS = "BLOG_SUB_CATEGORY_DELETE_SUCCESS";
export const BLOG_SUB_CATEGORY_DELETE_FAIL = "BLOG_SUB_CATEGORY_DELETE_FAIL";

  