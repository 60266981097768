export const SUBMENU_OVERVIEW_LIST_REQUEST = "SUBMENU_OVERVIEW_LIST_REQUEST";
export const SUBMENU_OVERVIEW_LIST_SUCCESS = "SUBMENU_OVERVIEW_LIST_SUCCESS";
export const SUBMENU_OVERVIEW_LIST_FAIL = "SUBMENU_OVERVIEW_LIST_FAIL";

export const SUBMENU_OVERVIEW_DETAILS_REQUEST = "SUBMENU_OVERVIEW_DETAILS_REQUEST";
export const SUBMENU_OVERVIEW_DETAILS_SUCCESS = "SUBMENU_OVERVIEW_DETAILS_SUCCESS";
export const SUBMENU_OVERVIEW_DETAILS_FAIL = "SUBMENU_OVERVIEW_DETAILS_FAIL";

export const SUBMENU_OVERVIEW_SAVE_REQUEST = "SUBMENU_OVERVIEW_SAVE_REQUEST";
export const SUBMENU_OVERVIEW_SAVE_SUCCESS = "SUBMENU_OVERVIEW_SAVE_SUCCESS";
export const SUBMENU_OVERVIEW_SAVE_FAIL = "SUBMENU_OVERVIEW_SAVE_FAIL";

export const SUBMENU_OVERVIEW_DELETE_REQUEST = "SUBMENU_OVERVIEW_DELETE_REQUEST";
export const SUBMENU_OVERVIEW_DELETE_SUCCESS = "SUBMENU_OVERVIEW_DELETE_SUCCESS";
export const SUBMENU_OVERVIEW_DELETE_FAIL = "SUBMENU_OVERVIEW_DELETE_FAIL";

