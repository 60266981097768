export const MENU_LIST_REQUEST = "MENU_LIST_REQUEST";
export const MENU_LIST_SUCCESS = "MENU_LIST_SUCCESS";
export const MENU_LIST_FAIL = "MENU_LIST_FAIL";

export const MENU_DETAILS_REQUEST = "MENU_DETAILS_REQUEST";
export const MENU_DETAILS_SUCCESS = "MENU_DETAILS_SUCCESS";
export const MENU_DETAILS_FAIL = "MENU_DETAILS_FAIL";

export const MENU_SAVE_REQUEST = "MENU_SAVE_REQUEST";
export const MENU_SAVE_SUCCESS = "MENU_SAVE_SUCCESS";
export const MENU_SAVE_FAIL = "MENU_SAVE_FAIL";

export const MENU_DELETE_REQUEST = "MENU_DELETE_REQUEST";
export const MENU_DELETE_SUCCESS = "MENU_DELETE_SUCCESS";
export const MENU_DELETE_FAIL = "MENU_DELETE_FAIL";

  