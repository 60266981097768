export const CONSULTATION_OVERVIEW_LIST_REQUEST = "CONSULTATION_OVERVIEW_LIST_REQUEST";
export const CONSULTATION_OVERVIEW_LIST_SUCCESS = "CONSULTATION_OVERVIEW_LIST_SUCCESS";
export const CONSULTATION_OVERVIEW_LIST_FAIL = "CONSULTATION_OVERVIEW_LIST_FAIL";

export const CONSULTATION_OVERVIEW_DETAILS_REQUEST = "CONSULTATION_OVERVIEW_DETAILS_REQUEST";
export const CONSULTATION_OVERVIEW_DETAILS_SUCCESS = "CONSULTATION_OVERVIEW_DETAILS_SUCCESS";
export const CONSULTATION_OVERVIEW_DETAILS_FAIL = "CONSULTATION_OVERVIEW_DETAILS_FAIL";

export const CONSULTATION_OVERVIEW_SAVE_REQUEST = "CONSULTATION_OVERVIEW_SAVE_REQUEST";
export const CONSULTATION_OVERVIEW_SAVE_SUCCESS = "CONSULTATION_OVERVIEW_SAVE_SUCCESS";
export const CONSULTATION_OVERVIEW_SAVE_FAIL = "CONSULTATION_OVERVIEW_SAVE_FAIL";

export const CONSULTATION_OVERVIEW_DELETE_REQUEST = "CONSULTATION_OVERVIEW_DELETE_REQUEST";
export const CONSULTATION_OVERVIEW_DELETE_SUCCESS = "CONSULTATION_OVERVIEW_DELETE_SUCCESS";
export const CONSULTATION_OVERVIEW_DELETE_FAIL = "CONSULTATION_OVERVIEW_DELETE_FAIL";

